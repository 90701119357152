import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { element } from 'protractor';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.css']
})
export class PaymentListComponent implements OnInit {
  kycForm: FormGroup;
  pageNumber: number = 1;
  paymentData: any = [];
  userId: any;
  url: string;
  constructor(public route: Router, public service: MainService, public transform:DatePipe) { }

  ngOnInit(): void {
    this.kyc();
    this.kycForm = new FormGroup({
      'status': new FormControl('', Validators.required),
      'userName': new FormControl('', Validators.required),
    })
  }

  // api of kyc listing
  kyc() {
    var url = "stripe/payment/get-payment-History?page=" + (this.pageNumber - 1) + "&pageSize=20";
    this.service.post(url,{}).subscribe((res: any) => {
      console.log("kyc", res);
      if (res.status == 200) {
        this.paymentData = res.data.content;
      }

    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // Api of search
  // search() {

  //   let kycStatus = this.kycForm.value.status;
  //   let search = this.kycForm.value.userName
  //   if (this.kycForm.value.userName && this.kycForm.value.status) {
  //     this.url = "account/admin/kyc-management/filter-kyc-users-list?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&kycStatus=" + kycStatus + "&search=" + search;
  //   }
  //   else if (this.kycForm.value.userName) {
  //     this.url = "account/admin/kyc-management/filter-kyc-users-list?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&search=" + search;
  //   }
  //   else if (this.kycForm.value.status) {
  //     this.url = "account/admin/kyc-management/filter-kyc-users-list?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&kycStatus=" + kycStatus;
  //   }
  //   this.service.get(this.url).subscribe((res: any) => {
  //     console.log('search', res);

  //     if (res['status'] == 200) {
  //       this.paymentData = res.data.list;
  //       this.service.toasterSucc('User found successfully')


  //     }

  //   }, (err) => {
  //     if (err['status'] == 401) {
  //       this.service.toasterErr('Unauthorized Access')
  //     }
  //     else {
  //       this.service.toasterErr('Something Went Wrong');
  //     }
  //   })

  // }

  // reset
  reset() {
    if (this.kycForm.value.userName || this.kycForm.value.status) {
      this.kycForm.reset();
      this.kyc();
    }
  }
  view(id) {
    console.log('gg', id);
    this.route.navigate(['/payment-view'], { queryParams: { id: id } })
  }



  
  exportAsXLSX() {
    let dataArr = [];
    this.paymentData.forEach((element, ind) => {
      dataArr.push({
        "S.No": ind + 1,
        "User Id": element.userId ? element.userId : 'N/A',
        "Payment Id": element.paymentId ? element.paymentId : 'N/A',
        "Card Number": element.nubmer ? element.nubmer : 'N/A',
        "Amount": element.amount ? element.amount : 'N/A',
        "Currency": element.currency ? element.currency : 'N/A',
        "Customer Id": element.customer ? element.customer : 'N/A',
        "TxnId": element.txnId ? element.txnId : 'N/A',
        "Card Id": element.cardId ? element.cardId : 'N/A',
        "Payment Status": element.paymentStatus ? element.paymentStatus : 'N/A',
        "Date & Time": element.createTime ? element.createTime : 'N/A',
      })
    })
    console.log(dataArr)
    this.service.exportAsExcelFile(dataArr, 'Payment List');
  }
}
