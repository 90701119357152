<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">{{service.davidant | titlecase}} Management</h1>
        </div>
        <div class="custom_tabs common-tabs">
            <div class="row mb20 justify-content-center">
                <div class="col-sm-12">
                    <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='GREM'}"
                                (click)="selectTab('GREM')" href="javascript:;">GREM {{service.davidant | titlecase}}</a>
                        </li>
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='GRET'}" (click)="selectTab('GRET')"
                                href="javascript:;">GRET {{service.davidant | titlecase}}</a>
                        </li>
                       
                    </ul>
                </div>
            </div>
        </div>

        <div class="tab-pane active show" *ngIf="this.currTab == 'GREM'">
            <app-set-grem-davidant></app-set-grem-davidant>

        </div>
        <div class="tab-pane active show" *ngIf="this.currTab == 'GRET'">

            <app-set-gret-davidant></app-set-gret-davidant>

        </div>
       





    </div>
</main>


