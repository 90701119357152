import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  activeUserLength: any;
  blockedUserLength: any;
  userListLength: any;
  pendingUserLength: any;
  kycpendingListlength: any;
  TotalUserCount: any;
  totalDepositeCount: any;
  totalCoinCount: any;
  totaltokenCount: any;
  totalUserwallet: any
  gremDavidant: any
  gretDavidant: any
  changeImage : boolean = false
  constructor(private router: Router, public service: MainService) { 
    
  }

  async ngOnInit() {
    this.getUserList();
    this.getListOfKyc();
    this.getListOfTotalUserCount();
    this.getDepositCoinCount();
    this.tokenRegister();
    this.getTotalActiveVendor()
    this.getTotalVendor()
    this.getGREMDavidentList()
    this.getGRETDavidentList()
    let coinArray = ['BTC', 'USDT', 'GREM', 'GRET', 'ETH']
    for (let item of coinArray) {
      await this.getAdminWallet(item)
    }
    this.getTotalUserWallet()

  }
  changeI(){
    this.changeImage = !this.changeImage
  }
  getTotalUserWallet() {
    let url = `wallet/wallet/get-coin-name-with-total-amount`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.totalUserwallet = res.data
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        // this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  adminHotWallet: any = {}
  async getAdminWallet(coinName) {
    var url = `wallet/admin/hot-cold-storage/get-storage-details-with-latestTime?coinName=${coinName}`;
    this.service.showSpinner();
    try {
      let res: any = await this.service.get(url).toPromise()
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.adminHotWallet[coinName] = res.data.HotWalletData.hotWalletBalance
        console.log(this.adminHotWallet);
      }
      err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          this.service.onLogout();
          this.service.toasterErr('Unauthorized Access');
        } else {
          // this.service.toasterErr('Something Went Wrong');
        }
      }
    } catch (error) {

    }

  }
  // Get List of User
  getUserList() {
    var url = "account/admin/dashboard/getUserCountByStatus";
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        var userList = res['data'];
        console.log('ffdhdj', userList);
        this.activeUserLength = userList.activeUsersCount;
        this.blockedUserLength = userList.blockedUsersCount;
        this.pendingUserLength = userList.pendingUserCount;
        this.userListLength = this.activeUserLength + this.blockedUserLength + this.pendingUserLength;
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        // this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // Get List of User
  getGRETDavidentList() {
    var url = "wallet/wallet/get-all-user-gret-amount";
    this.service.showSpinner();
    this.service.get(url).subscribe((res : any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.gretDavidant = res.data.Percentage

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        // this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // Get List of User
  getGREMDavidentList() {
    var url = "wallet/wallet/get-all-user-grem-amount";
    this.service.showSpinner();
    this.service.get(url).subscribe((res : any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.gremDavidant = res.data.GREM
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        // this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // getListOFKYC Function
  getListOfKyc() {
    var url = 'account/admin/dashboard/pendingKycCount';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.kycpendingListlength = res['data'];
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        // this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // getListOfTotalUserCount Function
  getListOfTotalUserCount() {
    var url = 'account/admin/dashboard/totalUserCount';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.TotalUserCount = res['data'];
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        // this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // get-deposit-and-coin-count Function
  getDepositCoinCount() {
    var url = 'wallet/get-deposit-and-coin-count';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.totalDepositeCount = res['data'].depositCount;
        this.totalCoinCount = res['data'].coinCount;
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // total token register Function
  tokenRegister() {
    var url = 'wallet/admin/get-token-count';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.totaltokenCount = res['data'].coinCount;

      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  totalVendor: any
  totalActiveVandor: any
  // total token register Function
  getTotalVendor() {
    var url = 'account/admin/dashboard/totalVendorCount';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.totalVendor = res['data'];

      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  getTotalActiveVendor() {
    var url = 'account/admin/dashboard/getVendorCountByStatus';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.totalActiveVandor = res['data'].activeUsersCount;

      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  toFixed(a, b) {
    return Number(a).toFixed(b)
  }

}
