import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-davidant',
  templateUrl: './davidant.component.html',
  styleUrls: ['./davidant.component.css']
})
export class DavidantComponent implements OnInit {

  currTab : any = ""
  constructor(public service : MainService) {
    
   }
  tradingDetail : any = []
  ngOnInit(): void {
    this.selectTab('GREM')
  }
  selectTab(tab){
    this.currTab = tab
  }

}
