<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">Ticket Management</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="userForm">
                                <div class="row form-group">
                                    <div class="col-md-2 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">Status </span>
                                                <select name="" id="" class="form-control form1"
                                                    formControlName="status">
                                                    <option value="">Select status</option>
                                                    <option value="RESOLVED">RESOLVED</option>
                                                    <option value="INPROGRESS">INPROGRESS</option>
                                                    <option value="CANCELLED">CANCELLED</option>
                                                    <option value="CLOSED">CLOSED</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">From Date </span>

                                                <input type="date" class="form-control form"
                                                    formControlName="startdate">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center">To Date</span>
                                                <input type="date" class="form-control form"
                                                    [min]="this.userForm.controls['startdate'].value"
                                                    formControlName="enddate">
                                            </div>
                                        </div>

                                    </div>
                                    <!-- <br> -->

                                    <!-- <div class="col-lg-2 col-md-2 col-sm-12  colum" style="margin-top:1.5%;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">
                                                <input type="text" class="form-control w-75"
                                                    placeholder="Search by email" formControlName="searchText">
                                                <div class="input-group-append">
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-2 col-md-2 col-sm-12">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center op-0"> Search</span>
                                                <input type="text" class="form-control" placeholder="Search by email"
                                                    formControlName="searchText">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-1 col-sm-6 pl-0 user-lst">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center op-0"> Search</span>
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    (click)="filterTicketList()">Search</button>
                                            </div>
                                        </div>




                                    </div>
                                    <div class="col-md-1 col-sm-6 pl-0 user-lst">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label text-right input-label1">
                                                <span class="d-flex align-items-center op-0"> Search</span>
                                                <button type="submit" class="btn  btn-theme" (click)="reset()">Reset
                                                </button>
                                            </div>
                                        </div>




                                    </div>

                                </div>

                            </form>
                        </div>
                        <div class="row  d-flex justify-content-end w_100">

                            <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                            </button>
                        </div>


                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <!-- <th scope="col">Message</th> -->
                                        <th scope="col">Email Address</th>
                                        <th scope="col">Customer Name</th>
                                        <th scope="col">Date & Time</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" class="action_td_btn3">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="new-hgt-tab" *ngFor="let item of ticketList| paginate: { 
                                        itemsPerPage: limit,
                                        currentPage: page,
                                        totalItems: totalRecords}; let i=index">
                                        <td>{{limit*(this.page-1)+i+1}}</td>
                                        <!-- <td class="elips">{{item.description || 'N/A'}}</td> -->
                                        <td>{{item.email || 'N/A'}}</td>
                                        <td>{{item.firstName || 'N/A'}}</td>
                                        <td>{{item.createdAt | date: 'medium'}}</td>
                                        <td>{{item.ticketStatus}}</td>
                                        <!-- <td class="new-hgt" style="min-width: 155px;">
                                            <select name="" id="" class="form-control hgt"
                                                (change)="updateTicketStatus($event,item.ticketId)">
                                                <option *ngFor="let status of arr"
                                                    [selected]="status == item.ticketStatus ? true : false">
                                                    <span>
                                                        {{status}}
                                                    </span>
                                                </option>

                                            </select>
                                        </td> -->

                                        <td class="action_td_btn3">
                                            <!-- <a routerLink="/view-ticket-reply/{{item.ticketId}}"><i class="fa fa-eye"
                                                    aria-hidden="true"></i></a> -->
                                                    <a routerLink="/view-ticket-reply/{{item.ticketId}}"><i class="fa fa-eye"
                                                        aria-hidden="true"></i></a>


                                        </td>
                                    </tr>
                                    <tr *ngIf="ticketList?.length==0">
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin-left: 10%;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>




<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Unblock </h3>
                        <h3 style="text-align: center; ">Block </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to unblock?</p>
                                <p style="font-size: 25px;">Are you sure you want to block?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>