<div >

    <!-- Aside End -->
    <main class="">

        <div class="">
            <div class="order-view max-WT-950 mrgn-0-auto">
                <div class="main-block-innner">
                    <div>
                        <form >
                            <div>
                                <div>
                                    <div>
                                        <div class="row justify-content-around">
                                            <div class="col-md-12 col-sm-12">
                                                <div class="myAlign">

                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">GREM {{mainService.davidant }} annually percentage</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6 d-flex">
                                                            <input type="number" max="100" min="0" class="form-control percentInput"
                                                                placeholder="GREM davidant Annually" (change)="setMonthly($event.target.value)"
                                                                onkeyup="if(this.value > 100) this.value = 100; if(this.value < 0) this.value = 0"
                                                                maxlength="100" [(ngModel)]="anualPrice" [ngModelOptions]="{standalone: true}" >
                                                                <input type="text" class="form-control percentLogoInput" disabled readonly value="%">
                                                            <!-- <div
                                                                *ngIf="addForm.controls['companyName'].hasError('required') && (addForm.controls['companyName'].touched ||addForm.controls['companyName'].dirty || submitted)">
                                                                <span class="error">*Please enter company name.</span>
                                                            </div> -->
                                                        </span>
                                                    </div>
                                                    <!-- <div class="form-group row">
                                                        <label class="col-md-5 bold">GREM {{mainService.davidant}} monthly percentage</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6 d-flex">
                                                            <input type="text" class="form-control percentInput"
                                                                placeholder="GREM davidant Monthly" disabled
                                                                maxlength="100" [(ngModel)]="monthlyPrice" [ngModelOptions]="{standalone: true}">
                                                                <input type="text" class="form-control percentLogoInput" disabled readonly value="%">
                                                        
                                                        </span>
                                                    </div> -->

                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">GREM {{mainService.davidant}} active</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <ui-switch uncheckedLabel="No" checkedLabel="Yes" labelOn="Yes" labelOff="No" [(ngModel)]="active"
                                                            [ngModelOptions]="{standalone: true}"
                                                            checkedTextColor="white" uncheckedTextColor="white" switchColor="#acaaaa" defaultBgColor="#fd1813"
                                                            (change)="activeBlockGremDaviant()"></ui-switch>
                                                            <!-- <div
                                                                *ngIf="addForm.controls['companyName'].hasError('required') && (addForm.controls['companyName'].touched ||addForm.controls['companyName'].dirty || submitted)">
                                                                <span class="error">*Please enter company name.</span>
                                                            </div> -->
                                                        </span>
                                                    </div>





                                                </div>

                                            </div>
                                            <div class="col-md-11 mt-2 text-center">
                                                <button class="btn  btn-theme cus-mr"
                                                  (click)="updateDavidant()"  >Submit</button>
                                                
                                            </div>
                                          
                                        </div>
                                  


                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>
