<div *ngIf="!isPreview" class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">Edit Vendor</h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view max-WT-950 mrgn-0-auto">
                <div class="main-block-innner">
                    <div>
                        <form [formGroup]="addForm">
                            <div>
                                <div>
                                    <div>
                                        <div class="row justify-content-around">
                                            <div class="col-md-12 col-sm-12">
                                                <div class="myAlign">
                                                    <h6><strong>
                                                            <h4>Basic Information Detail</h4>
                                                        </strong></h6>
                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">Company Name</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <input type="text" class="form-control"
                                                                placeholder="Company Name" formControlName="companyName"
                                                                maxlength="100">
                                                            <div
                                                                *ngIf="addForm.controls['companyName'].hasError('required') && (addForm.controls['companyName'].touched ||addForm.controls['companyName'].dirty || submitted)">
                                                                <span class="error">*Please enter company name.</span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">Company Address</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <textarea class="form-control" placeholder="Company Address"
                                                                formControlName="companyAddress"
                                                                maxlength="100"></textarea>
                                                            <!-- <input type="text" class="form-control" placeholder="Company Address"
                                                        formControlName="companyAddress" maxlength="100"> -->
                                                            <div
                                                                *ngIf="addForm.controls['companyAddress'].hasError('required') && (addForm.controls['companyAddress'].touched ||addForm.controls['companyAddress'].dirty || submitted)">
                                                                <span class="error">*Please enter company
                                                                    address.</span>
                                                            </div>
                                                            <div
                                                                *ngIf="addForm.controls['companyAddress'].hasError('pattern') && (addForm.controls['companyAddress'].touched ||addForm.controls['companyAddress'].dirty || submitted)">
                                                                <span class="error">*Please enter valid url.</span>
                                                            </div>
                                                        </span>
                                                    </div>




                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">Contact Person</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <input type="text" class="form-control"
                                                                placeholder="Contact Person"
                                                                formControlName="contactPerson" maxlength="100">
                                                            <div
                                                                *ngIf="addForm.controls['contactPerson'].hasError('required') && (addForm.controls['contactPerson'].touched ||addForm.controls['contactPerson'].dirty || submitted)">
                                                                <span class="error">*Please enter contact person.</span>
                                                            </div>
                                                            <div
                                                                *ngIf="addForm.controls['contactPerson'].hasError('pattern') && (addForm.controls['contactPerson'].touched ||addForm.controls['contactPerson'].dirty || submitted)">
                                                                <span class="error">*Please enter valid contact
                                                                    person.</span>
                                                            </div>
                                                        </span>
                                                    </div>

                                                    <!-- <div class="form-group row">
                                                <label class="col-md-5 bold">Address</label>
                                                <span class="col-md-1 sm-d-none">:</span>
                                                <span class="col-md-6">
                                                    <input type="text" class="form-control" placeholder="Address"
                                                        formControlName="address" maxlength="100">
                                                    <div
                                                        *ngIf="addForm.controls['address'].hasError('required') && (addForm.controls['address'].touched ||addForm.controls['address'].dirty)">
                                                        <span class="error">*Please enter address.</span>
                                                    </div>
                                                </span>
                                            </div> -->

                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">City</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <input type="text" class="form-control" placeholder="City"
                                                                formControlName="city" maxlength="100">
                                                            <div
                                                                *ngIf="addForm.controls['city'].hasError('required') && (addForm.controls['city'].touched ||addForm.controls['city'].dirty || submitted)">
                                                                <span class="error">*Please enter city.</span>
                                                            </div>
                                                        </span>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">Country</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <!-- <input type="text" class="form-control" placeholder="Country"
                                                        formControlName="country" maxlength="100"> -->
                                                            <select class="form-control" formControlName="country">
                                                                <option value="">Select Country</option>
                                                                <option [value]="item.name"
                                                                    *ngFor="let item of countryList">{{item.name}}
                                                                </option>
                                                            </select>
                                                            <div
                                                                *ngIf="addForm.controls['country'].hasError('required') && (addForm.controls['country'].touched ||addForm.controls['country'].dirty || submitted)">
                                                                <span class="error">*Please select Country.</span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">Email</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <input type="text" class="form-control" placeholder="Email"
                                                                formControlName="emailAddress" maxlength="100" readonly>
                                                            <div
                                                                *ngIf="addForm.controls['emailAddress'].hasError('required') && (addForm.controls['emailAddress'].touched ||addForm.controls['emailAddress'].dirty || submitted)">
                                                                <span class="error">*Please enter email address.</span>
                                                            </div>
                                                            <div
                                                                *ngIf="addForm.controls['emailAddress'].hasError('pattern') && (addForm.controls['emailAddress'].touched ||addForm.controls['emailAddress'].dirty || submitted)">
                                                                <span class="error">*Please enter valid email
                                                                    address.</span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">Phone Number</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <input type="text" class="form-control"
                                                                placeholder="Phone Number" (keypress)="mainService.preventSpace($event);mainService.numberOnly($event)" formControlName="phoneNumber"
                                                                maxlength="100">
                                                            <div
                                                                *ngIf="addForm.controls['phoneNumber'].hasError('required') && (addForm.controls['phoneNumber'].touched ||addForm.controls['phoneNumber'].dirty || submitted)">
                                                                <span class="error">*Please enter phone number.</span>
                                                            </div>
                                                        </span>
                                                    </div>




                                                </div>

                                            </div>
                                            <div class="col-md-12 col-sm-12">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="myAlign">
                                                            <h6><strong>
                                                                    <h4>Basic Account Detail</h4>
                                                                </strong></h6>
                                                            <div class="form-group row">
                                                                <label class="col-md-5 bold">IBAN</label>
                                                                <span class="col-md-1 sm-d-none">:</span>
                                                                <span class="col-md-6">
                                                                    <input type="text" class="form-control"
                                                                        placeholder="IBAN" formControlName="iban"
                                                                        maxlength="100">
                                                                    <div
                                                                        *ngIf="addForm.controls['iban'].hasError('required') && (addForm.controls['iban'].touched ||addForm.controls['iban'].dirty || submitted)">
                                                                        <span class="error">*Please enter IBAN.</span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-md-5 bold">Account Number</label>
                                                                <span class="col-md-1 sm-d-none">:</span>
                                                                <span class="col-md-6">
                                                                    <input type="text" class="form-control"
                                                                        placeholder="Account Number"
                                                                        formControlName="accountAddress"
                                                                        maxlength="100">
                                                                    <div
                                                                        *ngIf="addForm.controls['accountAddress'].hasError('required') && (addForm.controls['accountAddress'].touched ||addForm.controls['accountAddress'].dirty || submitted)">
                                                                        <span class="error">*Please enter account
                                                                            number.</span>
                                                                    </div>
                                                                    <div
                                                                        *ngIf="addForm.controls['accountAddress'].hasError('pattern') && (addForm.controls['accountAddress'].touched ||addForm.controls['accountAddress'].dirty || submitted)">
                                                                        <span class="error">*Please enter valid account
                                                                            address.</span>
                                                                    </div>
                                                                </span>
                                                            </div>




                                                            <div class="form-group row">
                                                                <label class="col-md-5 bold">Branch</label>
                                                                <span class="col-md-1 sm-d-none">:</span>
                                                                <span class="col-md-6">
                                                                    <input type="text" class="form-control"
                                                                        placeholder="Branch" formControlName="branch"
                                                                        maxlength="100">
                                                                    <div
                                                                        *ngIf="addForm.controls['branch'].hasError('required') && (addForm.controls['branch'].touched ||addForm.controls['branch'].dirty || submitted)">
                                                                        <span class="error">*Please enter branch
                                                                            name.</span>
                                                                    </div>
                                                                </span>
                                                            </div>





                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="row">
                                                    <div class="col-12 mt-2">
                                                        <div class="myAlign">
                                                            <h6><strong>
                                                                    <h4>USDT Address</h4>
                                                                </strong></h6>
                                                            <div class="form-group row">
                                                                <label class="col-md-5 bold">USDT Address</label>
                                                                <span class="col-md-1 sm-d-none">:</span>
                                                                <span class="col-md-6">
                                                                    <input type="text" class="form-control"
                                                                        placeholder="USDT Address"
                                                                        formControlName="usdtAddress" maxlength="100">
                                                                    <div
                                                                        *ngIf="addForm.controls['usdtAddress'].hasError('required') && (addForm.controls['usdtAddress'].touched ||addForm.controls['usdtAddress'].dirty || submitted)">
                                                                        <span class="error">*Please enter usdt
                                                                            address.</span>
                                                                    </div>
                                                                </span>
                                                            </div>





                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="row">
                                                    <div class="col-12 mt-2">
                                                        <div class="myAlign">
                                                            <h6><strong>
                                                                    <h4>Documents:</h4>
                                                                </strong></h6>
                                                            <div class="form-group row">
                                                                <label class="col-md-5 bold">Logo</label>
                                                                <span class="col-md-1 sm-d-none">:</span>
                                                                <span class="col-md-6">
                                                                    <input type="file" (change)="uploadImg($event,1)"
                                                                        class="form-control" placeholder="Company Name"
                                                                        maxlength="100">
                                                                    <div *ngIf="logoImageUrl">
                                                                        <a [href]="logoImageUrl" target="_blank">View
                                                                        </a>
                                                                    </div>

                                                                    <div *ngIf="!logoImageUrl && submitted">
                                                                        <span class="error">*Please upload logo
                                                                            image.</span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-md-5 bold">Trade license</label>
                                                                <span class="col-md-1 sm-d-none">:</span>
                                                                <span class="col-md-6">
                                                                    <input type="file" (change)="uploadImg($event,0)"
                                                                        class="form-control" placeholder="Company Name"
                                                                        maxlength="100"  accept=".jpeg,.jpg,.png,.pdf">
                                                                    <div *ngIf="tradeLicenceUrl">
                                                                        <a [href]="tradeLicenceUrl" target="_blank">View
                                                                        </a>
                                                                    </div>
                                                                    <div *ngIf="!tradeLicenceUrl && submitted">
                                                                        <span class="error">*Please upload trade licence
                                                                            image.</span>
                                                                    </div>
                                                                </span>
                                                            </div>





                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-md-12 mt-2">
                                                <h6><strong>Preferred method to receive payment from Global
                                                        REIT</strong></h6>
                                                <div class="row">
                                                    <div class="col-md-4 row">
                                                        <div class="col-md-3">
                                                            <input type="radio" [(ngModel)]="paymentMethod"
                                                                [ngModelOptions]="{standalone: true}" name="paymentmet"
                                                                value="USDT" class="form-control" style="height: 22px;"
                                                                id="USDT">
                                                        </div>
                                                        <div class="col-6">
                                                            <label for="USDT">USDT</label>
                                                        </div>



                                                    </div>
                                                    <div class="col-md-6 row">
                                                        <div class="col-md-3">
                                                            <input type="radio" [(ngModel)]="paymentMethod"
                                                                [ngModelOptions]="{standalone: true}" name="paymentmet"
                                                                value="BANKACCOUNT" class="form-control"
                                                                style="height: 22px;" id="USD">
                                                        </div>
                                                        <div class="col-9">
                                                            <label for="USD">Bank Account (USD)</label>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-md-11 mt-2 text-center">
                                                <button class="btn  btn-theme cus-mr"
                                                    (click)="addVndor()">Preview</button>
                                                <button class="btn  btn-theme cus-mr"
                                                    [routerLink]="['/vendor-list']">Back</button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>
<app-vendor-preview *ngIf="isPreview" (getIsPreview)="previewVal($event)"></app-vendor-preview>
