import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { countryList } from 'src/app/provider/country';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-set-grem-davidant',
  templateUrl: './set-grem-davidant.component.html',
  styleUrls: ['./set-grem-davidant.component.css']
})
export class SetGremDavidantComponent implements OnInit {


  active: any
  anualPrice: any
  monthlyPrice: any
  constructor(private router: Router, public mainService: MainService) {
  
   }

  ngOnInit() {
    this.getDavidantPercentage()

  }
  getDavidantPercentage() {
    let url = `wallet/coin/get-coin-list`
    this.mainService.showSpinner()
    this.mainService.get(url).subscribe(
      (res: any) => {
        console.log("kyc", res);
        if (res.status == 200) {
          let temp = res.data.find(x =>{
            return x.coinShortName == 'GREM'
          })
          console.log(temp);
          this.anualPrice = temp.devidentAmount
          this.active = temp.status == 'ACTIVE' ? true : false
          this.setMonthly()
          this.mainService.hideSpinner()
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.mainService.toasterErr("Unauthorized Access");
        } else {
          this.mainService.toasterErr("Something Went Wrong");
        }
        this.mainService.hideSpinner()

      }
    );
  }
  setMonthly(e?) {
    this.monthlyPrice = this.anualPrice / 12
  }
  updateGremActive() {
    console.log("i am called", this.active);

  }
  updateDavidant(){
    let url = `wallet/admin/fee-management/set-divident-percentage-amount?coinName=GREM&devidentPercentage=${this.anualPrice}`
    this.mainService.showSpinner()
    this.mainService.get(url).subscribe(
      (res: any) => {
       
        if (res.status == 200) {
          this.mainService.toasterSucc("Updated Successfully");
          this.mainService.hideSpinner()
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.mainService.toasterErr("Unauthorized Access");
        } else {
          this.mainService.toasterErr("Something Went Wrong");
        }
        this.mainService.hideSpinner()

      }
    );
  }

  //----- validation -------//

activeBlockGremDaviant(){
 
  let url = `wallet/admin/fee-management/admin/active-block-dividened-percentage?coinName=GREM&status=${this.active ? 'BLOCK' : 'ACTIVE'}`
  this.mainService.showSpinner()
  this.mainService.post(url,{}).subscribe(
    (res: any) => {
     
      if (res.status == 200) {
        this.mainService.toasterSucc("Updated Successfully");
        this.mainService.hideSpinner()
      }
    },
    (err) => {
      if (err["status"] == 401) {
        this.mainService.toasterErr("Unauthorized Access");
      } else {
        this.mainService.toasterErr("Something Went Wrong");
      }
      this.mainService.hideSpinner()

    }
  );
}


}
