import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-all-user-wallet',
  templateUrl: './all-user-wallet.component.html',
  styleUrls: ['./all-user-wallet.component.css']
})
export class AllUserWalletComponent implements OnInit {
  userForm: FormGroup;
  ticketList: any = [];
  page: number = 1;
  limit: number = 20;
  totalRecords: any;
  pageNumber: number = 1;
  arr: any = ['RESOLVED', 'INPROGRESS', 'CLOSED', 'CANCELLED']
  filterList: any = []
  updatestatus: string;
  isSearched: boolean = false
  showSpinner : boolean =true
  constructor(
    private router: Router, public service: MainService
  ) {

  }

  ngOnInit() {
    this.userForm = new FormGroup({
      'email': new FormControl(''),
      'address': new FormControl(''),

      'coinName': new FormControl(''),

    })

    this.getTicketList();
  }


  getTicketList(isSearched?) {

    // page=${this.pageNumber - 1}&pageSize=${this.limit}${coinName ? '&coinName=' + coinName : ''}${address ? '&address=' + address : ''}${email ? (isEmail ? '&userEmail='+email : '&userName='+email ):''}
    let url = `wallet/get-all-user-wallet-transaction-history`
    this.showSpinner = true
    this.service.get(url).subscribe((res: any) => {
      const temp = res.data
      let finalData = [];
      let finalItemDetails = {}
      let tempID;
      this.filterList = []
      this.showSpinner = true
      temp.forEach((item: any, idx: number) => {
       
        if (item.fkUserId === tempID) {
          finalItemDetails = { ...finalItemDetails, [item.coinName]: item }
        }
        else {
          if (!tempID) {
            tempID = item.fkUserId;
            finalItemDetails = { ...finalItemDetails, [item.coinName]: item }
          } else {
            finalData.push(finalItemDetails)
            tempID = item.fkUserId;
            finalItemDetails = {}
            finalItemDetails = { ...finalItemDetails, [item.coinName]: item }
          }
        }
        if (idx == (temp.length - 1)) {
         this.showSpinner = false
          if (isSearched) { 
            setTimeout(() => {
              this.filterList = []

              this.search()
            }, 500);
          }


        }
      })

      if (!temp.length) {
       
        this.showSpinner = false

      }
      // else{
      //   setTimeout(() => {
      //     this.service.hideSpinner()
      //   }, 20000);
      // }

      console.log("finalData", finalData)
      this.filterList = finalData
      this.ticketList = finalData
      this.totalRecords = finalData.length
      // this.service.hideSpinner()
    })
  }
  search() {
    let coinName = this.userForm.value.coinName
    let searchText: string = String(this.userForm.value.email).toLowerCase() ? String(this.userForm.value.email).toLowerCase() : ''
    let address = String(this.userForm.value.address).toLowerCase()
    // let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    // let isEmail = searchText.match(emailRegex)

    if (address || coinName) {
      this.userForm.patchValue({
        email: ''
      })
      if (!address) {
        this.service.toasterErr('Please enter wallet address')
        return
      }
      if (!coinName) {
        this.service.toasterErr('Please coin name')
        return
      }
      this.filterList = this.ticketList.filter((item) => {

        let walletAddress = String(item[coinName]?.walletAddress).toLowerCase()
        return address == walletAddress


      })
    }
    else {
      this.filterList = this.ticketList.filter((item) => {
        console.log(item);

        let filterByEmail = String(item.BTC?.email || item.ETH?.email || item.USDT?.email || item.GREM?.email || item.GRET?.email).toLowerCase()
        let filterUserName = String(item.BTC?.userName || item.ETH?.userName || item.USDT?.userName || item.GREM?.userName || item.GRET?.userName).toLowerCase()
        return filterByEmail.includes(searchText) || filterUserName.includes(searchText)
      })
    }



    this.totalRecords = this.filterList.length
  }



  clearFilter() {
    this.userForm.patchValue({

      'address': '',

      'coinName': '',
    })
  }
  // reset
  reset() {
    this.isSearched = false
    this.userForm.reset({
      'email': '',
      'address': '',

      'coinName': '',
    })
    this.pageNumber = 1
    this.getTicketList()
  }

  replyTicket() {
    this.router.navigate(['/reply-ticket'])
  }
  viewTicket() {
    this.router.navigate(['/view-ticket'])
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.ticketList.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Email Address": element.BTC?.email ? element.BTC?.email : '',
        "User Name": element?.BTC?.userName ? element?.BTC?.userName : '',
        "BTC": element?.BTC?.walletAddress ? `address:- ${element?.BTC?.walletAddress}, balance:- ${element?.BTC?.walletBalance}` : '',
        "ETH": element?.ETH?.walletAddress ? `address:- ${element?.ETH?.walletAddress}, balance:- ${element?.ETH?.walletBalance}` : '',
        "USDT": element?.USDT?.walletAddress ? `address:- ${element?.USDT?.walletAddress}, balance:- ${element?.USDT?.walletBalance}` : '',
        "GREM": element?.GREM?.walletAddress ? `address:- ${element?.GREM?.walletAddress}, balance:- ${element?.GREM?.walletBalance}` : '',
        "GRET": element?.GRET?.walletAddress ? `address:- ${element?.GRET?.walletAddress}, balance:- ${element?.GRET?.walletBalance}` : '',
        
      })
    })

    this.service.exportAsExcelFile(dataArr, 'All user wallet');
    console.log(dataArr)
  }
  pagination(e) {
    this.page = e
    this.pageNumber = e
    // this.getTicketList()
    // if (this.isSearched) {
    //   this.filterTicketList()
    // }
    // else {

    //   this.getTicketList()
    // }

  }
  coinName: any
  userId: any
  balance: any
  updateBalance(coinName, userId, balance) {

    this.coinName = coinName
    this.userId = userId
    this.balance = balance
    $('#updateBalaneModal').modal({ backdrop: 'static' })
  }
  updateBalAPI() {
    let data = {

    }
    this.service.post(`wallet/wallet/update-wallet-balance?coinName=${this.coinName}&fkUserId=${this.userId}&walletBalance=${this.balance}`, data).subscribe((res: any) => {
      this.service.showSpinner()
      if (res.status = 200) {
        this.service.hideSpinner()
        this.service.toasterSucc(res.message)
        $('#updateBalaneModal').modal('hide')

        this.getTicketList(true)

      }
    })
  }

}
