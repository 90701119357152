<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">Payment Management</h1>
    </div>
    <!-- <div class="row">
        <div class="col-md-12">
         <div class="d-flex justify-content-end">
            <h6 class="kycReqired">User Kyc Required</h6>
            <input class="top-check-data" type="checkbox" checked data-toggle="toggle" data-on="YES" data-off="NO">
         </div>
        </div>
    </div> -->
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                            <!-- <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="kycForm">
                                <div class="row form-group">
                                    <div class="col-md-3">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="status" class="form-control form1">
                                                    <option value="">Select status</option>
                                                    <option>PENDING</option>
                                                    <option>ACCEPTED</option>
                                                    <option>REJECTED</option>
                                                </select>    
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-3 col-sm-12 user-lst">
                                        <div class="">
                                            <div class="text-left">
                                                <input type="text" placeholder="Username" class="form-control form "
                                                    formControlName="userName">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 user-lst" >
                                        <div class="two-btnline">
                                            <div class="text-left" style="margin-left: 1%" (click)="search()">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    >Search</button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="col-md-2 col-sm-6 user-lst">
                                      
                                        <div class="two-btnline sm-left">
                                            <div class="text-left" style="margin-left: 5px" (click)="reset()">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    >Reset</button>
                                            </div>
                                        </div>
                                    </div>

                                   
                                    <div class="col-md-2 col-sm-12" >

                                        <button type="submit"  class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                                        </button>
                                    </div>
                                </div>
                             
                            </form>
                        </div> -->
                        <div class="col-md-12 col-sm-12 exprt" >

                            <button type="submit"  class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                            </button>
                        </div>
                        
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col">User Id</th>
                                        <th scope="col">Payment Id</th>
                                        <th scope="col">Card Number</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Currency</th>
                                        <th scope="col">Customer Id</th>
                                        <th scope="col">TxnId</th>
                                        <th scope="col">Card Id</th>
                                        <th scope="col">Payment Status</th>
                                        <th scope="col">Date & Time</th>
                                        <th scope="col">Action</th>
                                    </tr>  
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of paymentData | paginate:{itemsPerPage:20, currentPage:pageNumber, totalItems:paymentData?.length}; let i=index">
                                        <td>{{20*(this.pageNumber-1)+i+1}}</td>
                                        <td>{{data.userId || 'N/A'}}</td>
                                        <td>{{data.paymentId || 'N/A'}}</td>
                                        <td>{{data.nubmer || 'N/A'}}</td>
                                        <td>{{data.amount || 'N/A'}}</td>
                                        <td>{{data.currency || 'N/A'}}</td>
                                        <td>{{data.customer || 'N/A'}}</td>
                                        <td>{{data.txnId || 'N/A'}}</td>
                                        <td>{{data.cardId || 'N/A'}}</td>
                                        <td>{{data.paymentStatus || 'N/A'}}</td>
                                        <td>{{(data.createTime | date: 'medium')}}</td>
                                        <td class="action_td_btn3">
                                            <a routerLink="/payment-view/{{data.userId}}"><i class="fa fa-eye"
                                                aria-hidden="true"></i></a>
                                            
                                        </td>
                                    </tr>
                                    <tr *ngIf="paymentData?.length==0">
                                        <td colspan="12" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                           >
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

