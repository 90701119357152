import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { countryList } from 'src/app/provider/country';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-vendor-view',
  templateUrl: './vendor-view.component.html',
  styleUrls: ['./vendor-view.component.css']
})
export class VendorViewComponent implements OnInit {





  userId: any
 
  constructor(private router: Router, public mainService: MainService, private activated: ActivatedRoute) {
    this.activated.queryParams.subscribe((res) => {
      this.userId = res.id
    })
  }

  ngOnInit() {

    // this.addBannerFormValidation();
    this.getVendorDetail()
    this.getVendorWalletAddress()
  }

  
  vendorDetail : any = {}
  getVendorDetail() {
    let url = `account/admin/user-management/user-details?userId=${this.userId}`
    this.mainService.showSpinner();
    this.mainService.get(url).subscribe((res: any) => {
      if (res?.status == 200) {
        this.vendorDetail = res['data']
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res["message"]);

      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res["message"]);
      }
    });
  }
  walletAddres : any 
  getVendorWalletAddress(){
    let url = `wallet/wallet/get-address-for-redeem?coinName=GRET&fkUserId=${this.userId}`
   
    this.mainService.get(url).subscribe((res: any) => {
      if (res?.status == 200) {
        
      this.walletAddres = res.data.walletAddress

      } else {
       
        this.mainService.toasterErr(res["message"]);
      }
    });
  }
  back() {
    this.router.navigate(['/advertisement-list'])
  }
  copyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      this.mainService.toasterSucc('Copied to clipboard')
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }

}
