import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { countryList } from 'src/app/provider/country'

@Component({
  selector: 'app-vendor-add',
  templateUrl: './vendor-add.component.html',
  styleUrls: ['./vendor-add.component.css']
})
export class VendorAddComponent implements OnInit {

  addForm: FormGroup
  advertisementList: any;

  imgSrc: any = []
  productImageDto: any = []

  image: string;
  imageuploaded: boolean = false;
  logoImageUrl: any
  tradeLicenceUrl: any
  countryList: any = countryList
  isPreview : boolean = false
  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {

    this.addBannerFormValidation();
  }


  //----- validation -------//
  addBannerFormValidation() {
    this.addForm = new FormGroup({


      companyName: new FormControl("", [Validators.required]),
      companyAddress: new FormControl("", [Validators.required]),
      contactPerson: new FormControl("", [Validators.required]),
      // address: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      country: new FormControl("", [Validators.required]),
      emailAddress: new FormControl("", [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      phoneNumber: new FormControl("", [Validators.required]),

      iban: new FormControl("", [Validators.required]),
      accountAddress: new FormControl("", [Validators.required]),
      branch: new FormControl("", [Validators.required]),
      // usdtAddress: new FormControl("", [Validators.required]),
      // logoImage: new FormControl("", [Validators.required]),
      // tradelicenceimage: new FormControl("", [Validators.required]),
      // paymentMethod: new FormControl("", [Validators.required]),
    })

  }
// -=-=-=-
previewVal(e){
  this.isPreview = e
//   const file1 = (this.logoFile as File)
//   const file2 = (this.tradeFile as File)
//  this.addForm.get('logoImage').setValue(file1)
//  this.addForm.get('tradelicenceimage').setValue(file2)


}
paymentMethod = 'USDT'
// [][][][][]
  submitted: boolean = false
  addVndor() {
    this.submitted = true
    if (this.addForm.invalid) {
      return
    }
    if (!this.logoImageUrl || !this.tradeLicenceUrl) {
      // this.mainService.toasterErr("Please upload logo image and trade licence image")
      this.mainService.toasterErr("Please wait while uplaoding logo image and trade licence image.")
      return
    }



    let temp = countryList.find((ele) => {
      return ele.name = this.addForm.value.country
    })
    let url = `account/vendor?preferPayment=${this.addForm.value.paymentMethod}`
    const data = {
      // contactPerson

      "lastName": this.addForm.value.contactPerson,
      "accountNo": this.addForm.value.accountAddress,
      "address": this.addForm.value.companyAddress,
      "branch": this.addForm.value.branch,

      "city": this.addForm.value.city,
      "country": this.addForm.value.country,
      "countryCode": temp.dial_code,


      "email": this.addForm.value.emailAddress,
      "firstName": this.addForm.value.companyName,
      "iban": this.addForm.value.iban,


      "password": "Mobiloitte@1",
      "phoneNo": this.addForm.value.phoneNumber,
      // "walletAddress": this.addForm.value.usdtAddress,
      "preferredPaymentMethod": this.paymentMethod,
      "roleStatus": "VENDOR",

      "logo": this.logoImageUrl,
      "tradeLicenceLogo": this.tradeLicenceUrl


    }
    localStorage.setItem('vendorData',JSON.stringify(data))
    // this.router.navigate(['/vendor-preview'])
    this.isPreview = true
    return
    this.mainService.showSpinner();
    this.mainService.postApi(url, data).subscribe((res) => {
      if (res["status"] == 200) {
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res["message"]);
        this.router.navigate(['/vendor-list'])

      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res["message"]);
      }
    });
  }
  addBankDetail(uid) {
    // if(this.imgSrc[0] == 'assets/img/loaderadvertisement.gif'){
    //   this.mainService.toasterInfo("Please wait while image is uploading")
    //   return
    // }
    let url = "account/add-user-bank-account"
    const data = {
      "ibanNo": this.addForm.value.iban,
      "accountNo": this.addForm.value.accountAddress,
      "bankName": this.addForm.value.branch,



    }


    this.mainService.showSpinner();
    this.mainService.postApi(url, data).subscribe((res) => {
      if (res["status"] == 200) {
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res["responseMessage"]);

      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res["responseMessage"]);
      }
    });
  }

  //--------- upload image functionality-------//

  logoFile : any
  tradeFile : any
 

  back() {
    this.router.navigate(['/advertisement-list'])
  }


  uploadImg(event,type): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img,type);
  }
  uploadImageFunc(img,type) {
    var fb = new FormData();
    fb.append('file', img)
    this.mainService.showSpinner();
    this.mainService.postApi('account/upload-file', fb).subscribe((res: any) => {
      console.log(res);
      if (res["status"] == 200) {
        if (type) {
          this.logoImageUrl = res['data']

          this.logoFile = img
        }
        else {
          this.tradeLicenceUrl = res["data"]
          this.tradeFile = img
        }
        // this.imgSrc[0] = res["data"]
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res["message"]);
      }
      else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res["message"]);
      }
    })
  }

}
