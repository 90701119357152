import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-set-gret-davidant',
  templateUrl: './set-gret-davidant.component.html',
  styleUrls: ['./set-gret-davidant.component.css']
})
export class SetGretDavidantComponent implements OnInit {


  active: any
  anualPrice: any
  monthlyPrice: any
  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.getDavidantPercentage()

  }
  getDavidantPercentage() {
    let url = `wallet/coin/get-coin-list`
    this.mainService.showSpinner()
    this.mainService.get(url).subscribe(
      (res: any) => {
        console.log("kyc", res);
        if (res.status == 200) {
          let temp = res.data.find(x =>{
            return x.coinShortName == 'GRET'
          })
          console.log(temp);
          this.anualPrice = temp.devidentAmount
          this.setMonthly()
          this.mainService.hideSpinner()
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.mainService.toasterErr("Unauthorized Access");
        } else {
          this.mainService.toasterErr("Something Went Wrong");
        }
        this.mainService.hideSpinner()

      }
    );
  }
  setMonthly(e?) {
    this.monthlyPrice = this.anualPrice / 12
  }
  updateGremActive() {
    console.log("i am called", this.active);

  }
  updateDavidant(){
    let url = `wallet/admin/fee-management/set-divident-percentage-amount?coinName=GRET&devidentPercentage=${this.anualPrice}`
    this.mainService.showSpinner()
    this.mainService.get(url).subscribe(
      (res: any) => {
       
        if (res.status == 200) {
          this.mainService.toasterSucc("Updated Successfully");
          this.mainService.hideSpinner()

        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.mainService.toasterErr("Unauthorized Access");
        } else {
          this.mainService.toasterErr("Something Went Wrong");
        }
        this.mainService.hideSpinner()

      }
    );
  }


}
