<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">REDEMPTION HISTORY</h1>
    </div>

    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">

                        <!-- <div class="user-main-head"> -->
                        <div class="user-main-head sec_head_new sec_head_new_after">

                            <!-- <p
                                style="margin-top: -3%;font-size: 124%;background-color: white; width: 10%; padding-left: 1%;">
                                Filter By</p> -->
                            <form>
                                <div class="row form-group">
                                    <!-- <span style=" margin-top: 1%">Search By</span> -->

                                    <!-- <div class="col-md-1" style="padding-top: 2%;">
                                        <label for="" style="white-space: nowrap">Search By:</label>
                                    </div> -->
                                    <!-- <div class="col-md-2" style="padding-top: 1%;">
                                        <select formControlName="status" class="form-control form1">
                                            <option value="">Select status</option>
                                            <option>PENDING</option>
                                            <option>ACCEPTED</option>
                                            <option>REJECTED</option>
                                        </select>
                                    </div> -->
                                    <!-- <div class="col-md-3">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="status" class="form-control form1">
                                                    <option value="">Select status</option>
                                                    <option>PENDING</option>
                                                    <option>ACCEPTED</option>
                                                    <option>REJECTED</option>
                                                </select>    
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="col-md-4 col-sm-12 user-lst">
                                        <div class="">
                                            <div class="text-left">
                                                <input type="text" [(ngModel)]="searchText"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Vendor email"
                                                    class="form-control form ">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-2 col-sm-12 user-lst">
                                        <div class="">
                                            <div class="text-left">
                                                <input type="date" [(ngModel)]="fromDate" [max]="todayDate"
                                                    [ngModelOptions]="{standalone: true}" 
                                                    class="form-control form ">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 user-lst">
                                        <div class="">
                                            <div class="text-left">
                                                <input type="date" [(ngModel)]="toDate" [readonly]="!fromDate" [min]="fromDate"
                                                    [ngModelOptions]="{standalone: true}"
                                                    class="form-control form ">
                                            </div>
                                        </div>
                                    </div> -->



                                    <div class="col-md-2 col-sm-6 user-lst">
                                        <div class="two-btnline">
                                            <div class="text-left" style="margin-left: 1%" (click)="pageNumber=1;kyc()">
                                                <button type="submit" class="btn  btn-theme cus-mr">Search</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-2 col-sm-6 user-lst">

                                        <div class="two-btnline sm-left">
                                            <div class="text-left" style="margin-left: 5px" (click)="reset()">
                                                <button type="submit" class="btn  btn-theme cus-mr">Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-3">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    [routerLink]="['/vendor-add']">Add Vendor</button>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="col-md-2 col-sm-12">

                                        <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS
                                            EXCEL
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>


                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col">Vendor Name</th>
                                        <th scope="col">Vendor Email</th>
                                        <th scope="col">User Name</th>
                                        <th scope="col">User Email</th>
                                        <th scope="col">GRET Amount</th>
                                        <th scope="col">Txn. Id</th>

                                        <th scope="col">Date & Time</th>
                                        <th scope="col"> Status</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of vendorList | paginate:{itemsPerPage:itemPerPage, currentPage:pageNumber, totalItems:totalItem}; let i=index">
                                        <td>{{itemPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <td>{{data.vendorName || 'N/A'}}</td>
                                        <td>{{data.vendorEmail || 'N/A'}}</td>
                                        <td>{{data.userName || 'N/A'}}</td>
                                        <td>{{data.userEmail || 'N/A'}}</td>
                                        <td>{{data.amount || 'N/A'}}</td>
                                        <td>{{data.txnId || 'N/A'}}</td>
                                        <td>{{(data.txnTime | date : 'medium' )|| 'N/A'}}</td>
                                        <td>{{data?.status || 'N/A'}}</td>

                                        <!-- <td>
                                            <select class="form-control" (change)="updateStatus($event.target.value,data?.txnId)">
                                                <option value="SUCCESS" [selected]="data.status == 'SUCCESS'">SUCCESS</option>
                                                <option value="CANCELLED" [selected]="data.status == 'CANCELLED'">CANCELLED</option>

                                                <option value="PENDING" [selected]="data.status == 'PENDING'">PENDING</option>

                                            </select>
                                        </td> -->
                                        <!-- <td>
                                            <div class="d-flex">
                                                <a routerLink="/vendor-view" class="action_td_btn3"
                                                    [queryParams]="{id : data.userId}">
                                                    <i class="fa fa-eye" aria-hidden="true"
                                                        style="cursor: pointer;"></i>

                                                </a>
                                                <a routerLink="/vendor-edit" class="action_td_btn3 ml-2"
                                                    [queryParams]="{id : data.userId}"><em class="fa">&#xf044;</em></a>
                                                <a (click)="block(data.userStatus,data?.userId)"
                                                    class="action_td_btn3 ml-2"><em class="fa"
                                                        [ngStyle]="{'color': data.userStatus == 'ACTIVE' ? 'red' : 'green' }">&#xf05e;</em></a>
                                                <a (click)="delete(data?.userId)" class="action_td_btn3 ml-2"><em
                                                        class="fa" style="color: #ee6b7b;">&#xf1f8;</em></a>

                                            </div>

                                        </td> -->
                                    </tr>
                                    <tr *ngIf="vendorList?.length==0">
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin-left: 10%;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end">
                            <pagination-controls (pageChange)="changePage($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>





<!-- delete_modal Start -->
<!-- <div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete Vendor </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div> -->