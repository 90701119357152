import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-vendor-preview',
  templateUrl: './vendor-preview.component.html',
  styleUrls: ['./vendor-preview.component.css']
})
export class VendorPreviewComponent implements OnInit {

  @Output("getIsPreview") getIsPreview: EventEmitter<any> = new EventEmitter();


  userId: any
 
  constructor(private router: Router, public mainService: MainService, private activated: ActivatedRoute) {
    this.activated.queryParams.subscribe((res) => {
      this.userId = res.id
    })
  }

  ngOnInit() {

    // this.addBannerFormValidation();
    this.getVendorDetail()
  }

  
  vendorDetail : any = {}
  getVendorDetail() {
    
    this.vendorDetail = JSON.parse(localStorage.getItem('vendorData'))
    // let url = `account/admin/user-management/user-details?userId=${this.userId}`
    // this.mainService.showSpinner();
    // this.mainService.get(url).subscribe((res: any) => {
    //   if (res?.status == 200) {
    //     this.vendorDetail = res['data']
    //     this.mainService.hideSpinner();
    //     this.mainService.toasterSucc(res["message"]);

    //   } else {
    //     this.mainService.hideSpinner();
    //     this.mainService.toasterErr(res["message"]);
    //   }
    // });
  }
  addVendor(){
    let url = `account/vendor?preferPayment=${this.vendorDetail.preferredPaymentMethod}`
    if(this.userId){
       url = `account/vendor-updates?userId=${this.userId}`
    }
    
    this.mainService.showSpinner();
    this.mainService.postApi(url, this.vendorDetail).subscribe((res : any) => {
      if (res["status"] == 200) {
        this.mainService.hideSpinner();
        localStorage.removeItem('vendorData')
        if(this.userId){
          this.router.navigate(['/vendor-list'])
        }
        else{
         
          this.router.navigate(['/vendor-list'])
        }
        

      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res["message"]);
      }
    });
  }
  generateAddress(id){
    let url = `wallet/wallet/get-address-for-redeem?coinName=GRET&fkUserId=${id}`
    this.mainService.showSpinner();
    this.mainService.get(url).subscribe((res : any) => {
      if (res["status"] == 200) {
        this.mainService.hideSpinner();
        this.mainService.toasterSucc('Verification email send to associated vendor email address');
       
        
        this.router.navigate(['/vendor-list'])

      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res["message"]);
      }
    });
  }
  back() {
    // this.router.navigate(['/advertisement-list'])
    this.getIsPreview.emit(false)
  }


}
