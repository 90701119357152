import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-static-content-management',
  templateUrl: './static-content-management.component.html',
  styleUrls: ['./static-content-management.component.css']
})
export class StaticContentManagementComponent implements OnInit {
  staticData: any = []
  currTab: any = 'English';
  staticGermanData: any = [];
  staticSpanishData: any = [];
  staticContentData : any 
  constructor(public service: MainService, public router: Router) { }

  ngOnInit() {
    this.getEnglishStaticData()

  }

  // tab function
  selectTab(tab) {
    this.currTab = tab;
    if (this.currTab === 'English') {
      this.getEnglishStaticData()
    }
    else if (this.currTab === 'German') {
      console.log('hh', this.currTab);

      this.getGermanStaticData()

    }
    else if (this.currTab === 'Spanish') {
      this.getSpanishStaticData()

    }

  }
  staticDataS:any = []
  staticDataSs: any = []
  // english static data
  getEnglishStaticData() {
    this.service.showSpinner();
    this.service.get(`static/get-all-static-content-data`).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.staticData = res.data[0]
        this.staticDataS = res.data[1]
        this.staticDataSs = res.data[2]
       


      }
      // var arr = this.staticData;
      // console.log(arr.splice(0,arr.length-1));
      // this.staticContentData = arr.splice(0,arr.length-1)
      // console.log(this.staticContentData);
      
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // german static data
  getGermanStaticData() {
    this.service.showSpinner();
    this.service.get(`static/get-all-german-static-content-data`).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        console.log('ggf', res);
        this.staticGermanData = res.data
      }

    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // spanish static data
  getSpanishStaticData() {
    this.service.showSpinner();
    this.service.get(`static/get-all-spanish-static-content-data`).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        console.log('ggf', res);
        this.staticSpanishData = res.data
      }

    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // routing

  germanAbout(page, tab) {

    console.log('hgg', page);

    if (page == 'Über uns') {
      this.router.navigate(['/About_Us'], { queryParams: { tab: tab } })
    }
    else if (page == 'Datenschutzbestimmungen') {
      this.router.navigate(['/Privacy_Policy'], { queryParams: { tab: tab } })
    }

    else if (page == 'Geschäftsbedingungen') {
      this.router.navigate(['/Terms_And_Conditions'], { queryParams: { tab: tab } })
    }
    else if (page == 'kontaktiere uns') {
      this.router.navigate(['/Contact_Us'], { queryParams: { tab: tab } })

    }
  }
  englishAbout(page) {
    console.log('hgg', page);
    page = String(page).toLowerCase()
    this.router.navigate(['/About_Us'],{queryParams : {pageKey : page}})
    // if (page.includes('about')) {
    //   this.router.navigate(['/About_Us'])
    // }
    // else if (page.includes('privacy')) {
    //   this.router.navigate(['/Privacy_Policy'])
    // }

    // else if (page.includes('terms')) {
    //   this.router.navigate(['/Terms_And_Conditions'])
    // }
    // else if (page.includes('contact')) {
    //   this.router.navigate(['/Contact_Us'])

    // }
  }
  spanishAbout(page, tab) {
    console.log('hgg', page);

    if (page == 'Acerca de nosotros') {
      this.router.navigate(['/About_Us'], { queryParams: { tab: tab } })
    }
    else if (page == 'política de privacidad') {
      this.router.navigate(['/Privacy_Policy'], { queryParams: { tab: tab } })
    }

    else if (page == 'términos y condiciones') {
      this.router.navigate(['/Terms_And_Condition'], { queryParams: { tab: tab } })
    }
    else if (page == 'Contacta con nosotras') {
      this.router.navigate(['/Contact_Us'], { queryParams: { tab: tab } })

    }
  }
  // getName(name : string){
  //   return name.replace(/_/g," ");
  // }

}

