<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">KYC UPDATE</h1>
    </div>
    <div class="row">
        <div class="col-md-11 new-switch">
            <div class="d-flex justify-content-end strat-switch">
                <h6 class="kycReqired">User Kyc Required</h6>
                <!-- <input class="top-check-data" [(ngModel)]="text"  value="" type="checkbox"  (change)="kycRequired()"> -->
                <ui-switch uncheckedLabel="No" checkedLabel="Yes" labelOn="Yes" labelOff="No" [(ngModel)]="text"
                    checkedTextColor="white" uncheckedTextColor="white" switchColor="#acaaaa" defaultBgColor="#fd1813"
                    (change)="kycRequired()"></ui-switch>

            </div>
        </div>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">

                        <!-- <div class="user-main-head"> -->
                        <div class="user-main-head sec_head_new sec_head_new_after">

                            <!-- <p
                                style="margin-top: -3%;font-size: 124%;background-color: white; width: 10%; padding-left: 1%;">
                                Filter By</p> -->
                            <form [formGroup]="kycForm">
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-3 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">From Date </span>

                                                <input type="date" formControlName="fromDate" [max]="today" class="form-control form">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center">To Date</span>
                                                <input type="date" [readonly]="!kycForm.value.fromDate" [min]="kycForm.value.fromDate" formControlName="toDate" class="form-control form">
                                            </div>
                                        </div>

                                    </div>
                                    <br>

                                    <div class="col-lg-2 col-md-2 col-sm-12" style="margin-top:20px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">
                                                <select formControlName="status" class="form-control form1">
                                                    <option value="">Select status</option>
                                                    <option>PENDING</option>
                                                    <option>ACCEPTED</option>
                                                    <option>REJECTED</option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-12" style="margin-top:20px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">
                                                <input type="text" placeholder="Username" class="form-control form "
                                                    formControlName="userName">

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-2 user-lst p-0" style="margin-top: 20px; ">
                                        <div class="two-btnline">
                                            <div class="text-left">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    (click)="pageNumber =1;kyc()">Search</button>
                                            </div>
                                            <div class="head_flt_select">
                                                <div class="head_flt_select input-label">
                                                    <button type="submit" class="btn  btn-theme" (click)="reset()">Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>



                            </form>
                        </div>



                        <div class="table-responsive">
                            <div class="col-md-12 col-sm-12 p-0 text-right">



                                <button type="submit" class="btn  btn-theme m0" (click)="exportAsXLSX()">EXPORT AS
                                    EXCEL
                                </button>



                            </div>
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Mobile Number</th>
                                        <th scope="col">Document ID</th>
                                        <th scope="col">Date & Time</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" class="action_td_btn3">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of kycList | paginate:{itemsPerPage:itemPerPage, currentPage:pageNumber, totalItems:total}; let i=index">
                                        <td>{{itemPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <td>{{data.firstName || 'N/A'}}</td>
                                        <td>{{data.email || 'N/A'}}</td>
                                        <td>{{data.phoneNO || 'N/A'}}</td>
                                        <td>{{data.kycId || 'N/A'}}</td>
                                        <td>{{data.updateTime | date :'medium'}}</td>
                                        <td>{{data.kycStatus || 'N/A'}}</td>
                                        <td class="action_td_btn3" routerLink="/kyc-detail/{{data.userId}}">
                                            <a><em class="fa fa-eye"></em></a>
                                        </td>
                                    </tr>
                                    <tr *ngIf="kycList?.length==0">
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin-left: 10%;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end">
                            <pagination-controls (pageChange)="paginate($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>