import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-monthly-transaction',
  templateUrl: './monthly-transaction.component.html',
  styleUrls: ['./monthly-transaction.component.css']
})
export class MonthlyTransactionComponent implements OnInit {
  kycForm: FormGroup;
  pageNumber: number = 1;
  vendorList: any = [];
  userId: any;
  url: string;
  text: any = false;
  searchText: any
  totalItems: any
  pageLimit : any = 10
  today : any = new Date().toISOString().split('T')[0]
  constructor(
    public route: Router,
    public service: MainService,
    public transform: DatePipe
  ) { }

  ngOnInit(): void {
    
    this.kycForm = new FormGroup({
      fromDate : new FormControl("", Validators.required),
      toDate : new FormControl("", Validators.required),
      address : new FormControl("", Validators.required),
      searchText: new FormControl("", Validators.required),
      txnhash: new FormControl("", Validators.required),
    });
    this.kyc();
    console.log(this.text);
    // this.kycStatusChecked();
  }

  // api of kyc listing
  tempArray : any = []
  kyc() {
    // this.vendorList = [ 
    //   {name : 'Tanveer Haider' , email : 'tanveer@mailinator.com',greatAdd : '4545',status : 'Active' }
    // ]

    let fromDate = Date.parse(this.kycForm.value.fromDate)
    let toDate = Date.parse(this.kycForm.value.toDate)
    let address = this.kycForm.value.address
    let searchText = this.kycForm.value.searchText
    let txnhash = this.kycForm.value.txnhash

    var url = `wallet/wallet/get-all-monthly-dividened-transaction-history?page=${this.pageNumber -1}&pageSize=${this.pageLimit}${fromDate ? '&fromDate='+fromDate :''}${toDate ? '&toDate='+toDate : ""}`
    this.service.get(url).subscribe(
      (res: any) => {
        console.log("kyc", res);
        if (res.status == 200) {
          this.vendorList = res.data.resultlist;
          
          // this.tempArray = this.vendorList
          this.totalItems = res.data.totalCount
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  generateRandomAddress(){
    let address = `0x2cbf8eae996bb24fcec8fb4d${Math.floor(1000 + Math.random() * 9000)}c894b0f1e${Math.floor(1000 + Math.random() * 100)}`
    return address
  }
  kycRequiredCheckData: any;
  kycRequired() {
    console.log(this.text);

    this.service.showSpinner();
    // let kycRequiredCheck = this.coinTokenForm.value.coinNameToken;
    var url =
      "account/check-requried-kyc?checkId=" +
      "1" +
      "&kycIsCheck=" +
      (this.text == true ? "NO" : "YES");

    this.service.post(url, {}).subscribe(
      (res: any) => {
        console.log("j", res);
        this.service.hideSpinner();
        if (res["status"] == 200) {
          // this.kycRequiredCheckData = res["data"]["resultlist"];
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  KycStatusCheck: any;
  // get kyc check status
  kycStatusChecked() {
    var url = "account/get-check-kyc-status";
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.KycStatusCheck = res.data[0];
          this.text = this.KycStatusCheck.kycIsCheck == "YES" ? true : false;
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  // Api of search
  search(){
    // let kycStatus = this.kycForm.value.status;
      let search = String(this.searchText).toLowerCase()
      this.tempArray = this.vendorList.filter((ele)=>{
        let wallet = String(ele.walletAddress)
        let name = String(ele.firstName).toLowerCase()
        let email = String(ele.email).toLowerCase()
       
        return wallet.includes(search) || name.includes(search) || email.includes(search) 
      })
  }
  // search() {
  //   let kycStatus = this.kycForm.value.status;
  //   let search = this.kycForm.value.userName;
  //   if (this.kycForm.value.userName && this.kycForm.value.status) {
  //     this.url =
  //       "account/admin/kyc-management/filter-kyc-users-list?page=" +
  //       (this.pageNumber - 1) +
  //       "&pageSize=10" +
  //       "&kycStatus=" +
  //       kycStatus +
  //       "&search=" +
  //       search;
  //   } else if (this.kycForm.value.userName) {
  //     this.url =
  //       "account/admin/kyc-management/filter-kyc-users-list?page=" +
  //       (this.pageNumber - 1) +
  //       "&pageSize=10" +
  //       "&search=" +
  //       search;
  //   } else if (this.kycForm.value.status) {
  //     this.url =
  //       "account/admin/kyc-management/filter-kyc-users-list?page=" +
  //       (this.pageNumber - 1) +
  //       "&pageSize=10" +
  //       "&kycStatus=" +
  //       kycStatus;
  //   }
  //   this.service.get(this.url).subscribe(
  //     (res: any) => {
  //       console.log("search", res);

  //       if (res["status"] == 200) {
  //         this.vendorList = res.data.list;
  //         this.service.toasterSucc("User found successfully");
  //       }
  //     },
  //     (err) => {
  //       if (err["status"] == 401) {
  //         this.service.toasterErr("Unauthorized Access");
  //       } else {
  //         this.service.toasterErr("Something Went Wrong");
  //       }
  //     }
  //   );
  // }

  // reset
  reset() {
this.kycForm.reset()
    this.kyc()
  }
  userid: any
  userStatus: any
  block(status, id) {
    this.userid = id;
    this.userStatus = status == 'ACTIVE' ? 'BLOCK' : 'ACTIVE';
    $("#block").modal("show");
  }
  blockUser() {
    /* this.service.showSpinner(); */
    var url =
      "account/admin/user-management/user-status?userStatus=" +
      this.userStatus +
      "&userId=" +
      this.userid;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          this.service.toasterSucc(res.message);
          /*  this.service.hideSpinner();  */ this.kyc();
          $("#block").modal("hide");
        }
      },
      (err) => {
        /*  this.service.hideSpinner();   */
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  User() {
    /* this.service.showSpinner(); */
    var url =
      "account/admin/user-management/user-status?userStatus=" +
      this.userStatus +
      "&userId=" +
      this.userid;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          this.service.toasterSucc(res.message);
          /*  this.service.hideSpinner();  */ this.kyc();
          $("#block").modal("hide");
        }
      },
      (err) => {
        /*  this.service.hideSpinner();   */
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

   //========modal=======//
   id : any
   delete(id: number) {
    this.id = id;
    $("#deleteModal").modal("show");
  }
  deleteUser() {
    this.service
      .get(`account/admin/user-management/delete-user-detail?userId=${this.id}`)
      .subscribe(
        (res: any) => {
         
          if ((res.status = 200)) {
            $("#deleteModal").modal("hide");
            this.service.toasterSucc(res.message);
            this.kyc();
          }
        },
        (err) => {
          /* this.service.hideSpinner();  */ if (err["status"] == "401") {
            this.service.onLogout();
            this.service.toasterErr("Unauthorized Access");
          } else {
            this.service.toasterErr("Something Went Wrong");
          }
        }
      );
  }


  exportAsXLSX() {
    let dataArr = [];
    this.vendorList.forEach((element, ind) => {
      dataArr.push({
        "S.No": ind + 1,
        "Month": element.createTime ? this.transform.transform(element.createTime,'MMMM-yyyy') : "N/A",
        "Coin Type": element.coinType ? element.coinType : "N/A",
        "Total Amount": element.monthlyBalance ? element.monthlyBalance : "N/A"
        
      });
    });
    console.log(dataArr);
    this.service.exportAsExcelFile(dataArr, "MONTHLY DISTRIBUTED DIVIDENDS");
  }
  changePage(e){
    this.pageNumber = e
    this.kyc()

  }
}
