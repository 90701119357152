import { Component, OnInit } from '@angular/core';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-view-ticket-reply',
  templateUrl: './view-ticket-reply.component.html',
  styleUrls: ['./view-ticket-reply.component.css']
})
export class ViewTicketReplyComponent implements OnInit {
  id: number;
  ticketDetail: any;
  arr: any = ['RESOLVED', 'INPROGRESS', 'CLOSED', 'CANCELLED']
  adminProfilePic = localStorage.getItem('profile')
  isChatLoaded: boolean = true
  chatHistory: any = []
  constructor(private route: ActivatedRoute, private router: Router, public service: MainService,public _sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.id = +this.route.snapshot.paramMap.get('id')
    this.viewTicket();

  }
  viewTicket() {
    this.service.get(`static/view-ticket-detail?ticketId=${this.id}`).subscribe((res: any) => {
      this.ticketDetail = res.data
      this.viewTicketHistory()

    })
  }
  viewTicketHistory(editing?) {
    this.isChatLoaded = false
    this.service.get(`static/get-ticket-reply-data?ticketId=${this.id}`).subscribe((res: any) => {
      if (res.status == 200) {
        this.chatHistory = res.data
        let regex = /(https?:\/\/[^\s]+)/g
        for(let item of this.chatHistory){
          if(String(item.message).match(regex)){
            let tempArray = String(item.message).split('.');

            let messageExtention = tempArray[tempArray.length -1];
           
            let messageType = ''
            if(messageExtention == 'png' || messageExtention == 'jpg' || messageExtention == 'jpeg'){
              messageType = 'image'
            }
            else if(messageExtention == 'mp4' || messageExtention == 'mkv'){
              messageType = 'video'
            }
            else if(messageExtention == 'pdf'){
              messageType = 'PDF'
            }
            else{
              messageType = 'url'
            }
            item['messageType'] = messageType
          }
          else{
            item['messageType'] = 'text'
            
          }
        }
        console.log(this.chatHistory);
        
        this.isChatLoaded = true
        if (editing) {

        } else {

          this.refreshChat()
        }


      }


      else {
        this.isChatLoaded = true

      }
    })
  }

  reply() {
    let a = document.createElement('a')
    a.href = `mailto:${this.ticketDetail.email}?body=Hello! ${this.ticketDetail.firstName}`
    a.click()
  }
  copyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      this.service.toasterSucc('Copied to clipboard')
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }
  myMessage: any = ''
  isEdited: boolean = false
  messageId: any
  getMessage(id, message) {
    this.myMessage = message
    this.messageId = id
    this.isEdited = true
  }

  async pushMessage() {
    let msg = String(this.myMessage).trim()

    if (!msg) {
      this.myMessage = ''
      return
    }
    if (this.myMessage && this.isEdited) {
      let url = `static/update-reply-support-ticket?ticketReplyId=${this.messageId}`
      const body = {
        "message": this.myMessage
      }
      try {
        this.service.showSpinner()
        let res: any = await this.service.post(url, body).toPromise()
        if (res.status == 200) {
          this.myMessage = ''
          this.viewTicketHistory(true)
         
        }
        this.service.hideSpinner()

      } catch (error) {
        console.log(error);

      }
      this.myMessage = ''
      this.isEdited = false
    }
    else {

      this.isEdited = false
      if (this.myMessage) {

        // let temp = { id: this.chatHistory.length + 1, userType: 'Admin', message: this.myMessage, createdAt: new Date(), userEmail: 'ja-deepali122@mailinator.com', userName: 'Moin Khan' }
        // this.chatHistory.push(temp)
        let url = `static/add-support-ticket-reply?role=ADMIN`
        const body = {
          "message": this.myMessage,
          "ticketId": this.id
        }
        try {
          this.service.showSpinner()
          let res: any = await this.service.post(url, body).toPromise()
          if (res.status == 200) {
            this.myMessage = ''
            this.viewTicketHistory()
            this.closeModal()
          }
          this.service.hideSpinner()

        } catch (error) {
          console.log(error);

        }

      }
    }


  }
  refreshChat() {
    setTimeout(() => {
      var objDiv = document.getElementById("messageScreen");
      objDiv.scrollTop = objDiv.scrollHeight;

    }, 200);
  }

  updateTicketStatus(e) {
    let status = e.target.value
    let data = {}
    this.service.post(`static/change-ticket-status?ticketId=${this.id}&ticketStatus=${status}`, data).subscribe((res: any) => {
      this.service.showSpinner()
      if (res.status = 200) {
        this.service.hideSpinner()
        this.service.toasterSucc(res.message)
      }
    })
  }
  nextLineMessage(text) {
    let str = String(text).replace('\n', '<br/>')
    return str
  }
  selectedFile : any
  openGalery() {
    let input = document.createElement('input')
    input.type = "file"
    input.accept = ".jpeg,.png,.jpg,.mp4,.pdf,.mkv"
    input.click()
    input.addEventListener("change", handleFiles, false);
    let me = this
    function handleFiles() {
      const fileList = this.files; /* now you can work with the file list */
      console.log(fileList[0]);
      // me.uploadImageFunc(fileList[0])
      me.selectedFile = fileList[0]
      
      $('#mediaModal').modal({backdrop	: false})
      me.showMultimedia(fileList)
    }
  }
  fileName : any 
  fileLink : SafeResourceUrl
  fileType : any
  showMultimedia(file){
    console.log(file);
    this.fileName = file[0].name
    this.fileType = String(file[0].type).split('/')[0]
    let me = this
    if(this.fileType == 'image'){
      function encodeImageFileAsURL(element) {
        var file = element[0];
        var reader = new FileReader();
        reader.onloadend = function() {
          console.log('RESULT', reader.result)
          me.fileLink = reader.result
        }
        reader.readAsDataURL(file);
      }
      encodeImageFileAsURL(file)
    }
    else if(this.fileType == 'video' || this.fileType == 'application'){
      function encodeImageFileAsURL(element) {
     
        var file = element[0];
        console.log(URL.createObjectURL(file));
        
        // me.fileLink = URL.createObjectURL(file);
        // me.fileLink = me._sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
        me.fileLink = me._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file))
      }
      encodeImageFileAsURL(file)
    }
   
  }
  closeModal(){
    $('#mediaModal').modal('hide')
    this.fileLink = ''

  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        this.myMessage = res['data']
        this.pushMessage()

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // viewFile(url){
  //   let a = document.createElement('a')
  //   a.href = url
  //   a.target = "_blank"
  //   a.click()
  // }
}
