<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">VIEW TICKET </h1>
        </div>

        <!-- tab General -->
        <div class="tab-pane active show" >
            <div class="order-view mbt10 max-WT-950 mrgn-0-auto">
                <div class="main-block-innner mbt10 ">
                    <div class="input-style">
                        <div class="form-group row align-items-baseline">
                            <!-- <div class="row " style="width:10%">
                                <label class="col-md-2">Id</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-5">
                                    <label>{{ticketDetail?.ticketId}}</label>
                                </div>
                            </div> -->
                            <div class="col-md-4 row">
                                <label class="col-md-5">Customer Email</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-6">
                                    <label>{{ticketDetail?.email}}</label>
                                </div>
                            </div>
                            <div class="col-md-5 row">
                                <label class="col-md-4">Ticket Type</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label>{{ticketDetail?.supportType}}</label>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <select name="" id="" class="form-control hgt" (change)="updateTicketStatus($event)">
                                    <option *ngFor="let status of arr"
                                        [selected]="status == ticketDetail?.ticketStatus">

                                        {{status}}

                                    </option>
                                </select>
                            </div>
                            <div class="col-md-1">
                                <button class="btn btn-small" (click)="viewTicketHistory()"><i class="fa "
                                        [ngClass]="isChatLoaded ? '' : 'fa-spin'" aria-hidden="true">&#xf021;</i>
                                </button>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <div class="col-md-12 row">
                                <label class="col-md-2">Title</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label>{{ticketDetail?.subject}}</label>
                                </div>
                            </div>
                            <div class="col-md-12 row">
                                <label class="col-md-2">Description</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label>{{ticketDetail?.description}}</label>
                                </div>
                            </div>
                           
                            <div *ngIf="ticketDetail?.imageUrl" class="col-md-12 row">
                                <label class="col-md-2">View Attachment</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label>
                                        <a [href]="ticketDetail?.imageUrl" target="_blank"
                                            rel="noopener noreferrer">View</a>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <hr>
                        <div id="frame">

                            <div class="content">
                                <div class="contact-profile">
                                    <!-- <img [src]="http://emilcarlsson.se/assets/harveyspecter.png" alt="" /> -->
                                    <img [src]=" ticketDetail?.profileImage || 'http://emilcarlsson.se/assets/harveyspecter.png'"
                                        alt="" />

                                    <p>{{ticketDetail?.firstName || '--'}}</p>

                                </div>
                                <div class="messages" id="messageScreen">
                                    <ul>
                                        <ng-template ngFor let-item [ngForOf]="chatHistory">
                                            <li *ngIf="item?.role != 'ADMIN'" class="sent">
                                                <img [src]="ticketDetail?.profileImage || 'http://emilcarlsson.se/assets/mikeross.png'"
                                                    alt="" />
                                                <!-- <img src="http://emilcarlsson.se/assets/mikeross.png" alt="" /> -->

                                                <p *ngIf="item?.messageType == 'text'" class="message">{{item?.message}}
                                                    <br>
                                                    <br>
                                                    <small class="messageTime" style="float: right;">{{item?.createdAt
                                                        | date : 'MMM dd,
                                                        yyyy h:mm a'}}
                                                        <span class="dropdown-table m-0">
                                                            <span class="dropdown">
                                                                <i class="fa fa-angle-down action-arrow"
                                                                    data-toggle="dropdown" aria-hidden="true"></i>
                                                                <span class="dropdown-menu">
                                                                    <a class="dropdown-item"
                                                                        (click)="copyToClipboard(item?.message)">Copy</a>

                                                                </span>
                                                            </span>
                                                        </span>


                                                    </small>

                                                </p>
                                                <p *ngIf="item?.messageType != 'text'" class="message">
                                                    <span class="message">
                                                        <!-- File : {{item.messageType}} -->
                                                        <span>
                                                            <span *ngIf="item?.messageType == 'image'">
                                                                <img class="chatImage" [src]="item?.message" alt="">

                                                            </span>
                                                            <span *ngIf="item?.messageType == 'video'">
                                                                <video *ngIf="item?.message" width="250" height="100%"
                                                                    controls>
                                                                    <source [src]="item?.message" type="video/mp4">

                                                                    Your browser does not support the video tag.
                                                                </video>

                                                            </span>
                                                            <span *ngIf="item?.messageType == 'PDF'">
                                                                <!-- <img class="chatImage" src="assets/img/pdf-logo.jpg" alt="" srcset=""> -->
                                                                <object
                                                                    [data]="_sanitizer.bypassSecurityTrustResourceUrl(item?.message)"
                                                                    type="application/pdf" width="100%" height="100%">
                                                                    <iframe
                                                                        [src]="_sanitizer.bypassSecurityTrustResourceUrl(item?.message)"
                                                                        width="100%" height="100%"
                                                                        style="border: none;">
                                                                        <p>
                                                                            Your browser does not support PDFs.
                                                                            <a [href]="item?.message">Download the
                                                                                PDF</a>
                                                                            .
                                                                        </p>
                                                                    </iframe>
                                                                </object>
                                                            </span>
                                                            <span *ngIf="item?.messageType == 'url'">
                                                                <a [href]="item?.message" target="_blank"
                                                                    rel="noopener noreferrer">{{item?.message}}</a>
                                                            </span>
                                                            <hr>
                                                            <span class="d-flex justify-content-center">
                                                                <a [href]="item?.message" class="viewButton"
                                                                    target="_blank" download="FCG">
                                                                    <button *ngIf="item?.messageType != 'url'"
                                                                        class="btn btn-theme">View
                                                                        {{item?.messageType}}</button>
                                                                    <button *ngIf="item?.messageType == 'url'"
                                                                        class="btn btn-theme">Vist Link</button>
                                                                </a>

                                                            </span>

                                                            <br>
                                                            <br>
                                                            <small class="messageTime"
                                                                style="float: right;">{{item?.createdAt
                                                                | date : 'MMM dd,
                                                                yyyy h:mm a'}}
                                                            </small>

                                                        </span>

                                                    </span>

                                                </p>



                                            </li>
                                            <li *ngIf="item.role == 'ADMIN'" class="replies">
                                                <img [src]="adminProfilePic || 'http://emilcarlsson.se/assets/harveyspecter.png'"
                                                    alt="" />
                                                <!-- <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" /> -->
                                                <div class="d-flex justify-content-end align-items-center">
                                                    <div class="center" *ngIf="item.isEdited">

                                                        <i class="fa fa-pencil edited-icon"></i>
                                                        <!-- edited -->
                                                    </div>
                                                    <p *ngIf="item?.messageType == 'text'" class="message">
                                                        {{item?.message}}
                                                        <br>
                                                        <br>
                                                        <small class="messageTime"
                                                            style="float: right;">{{item.createdAt
                                                            | date : 'MMM dd,
                                                            yyyy h:mm a'}}
                                                            <span class="dropdown-table m-0">
                                                                <span class="dropdown">
                                                                    <i class="fa fa-angle-down action-arrow"
                                                                        data-toggle="dropdown" aria-hidden="true"></i>
                                                                    <span class="dropdown-menu">
                                                                        <a class="dropdown-item"
                                                                            (click)="copyToClipboard(item.message)">Copy</a>
                                                                        <a class="dropdown-item"
                                                                            (click)="getMessage(item.ticketReplyId,item.message)">Edit</a>

                                                                    </span>
                                                                </span>
                                                            </span>


                                                        </small>

                                                    </p>
                                                    <p *ngIf="item?.messageType != 'text'" class="">
                                                        <!-- fileMessage -->
                                                        <span class="message">
                                                            <!-- File : {{item.messageType}} -->
                                                            <span>
                                                                <span *ngIf="item?.messageType == 'image'">
                                                                    <img class="chatImage" [src]="item?.message" alt="">

                                                                </span>
                                                                <span *ngIf="item?.messageType == 'video'">
                                                                    <video *ngIf="item?.message" width="250"
                                                                        height="100%" controls>
                                                                        <source [src]="item?.message" type="video/mp4">

                                                                        Your browser does not support the video tag.
                                                                    </video>

                                                                </span>
                                                                <span *ngIf="item?.messageType == 'PDF'">
                                                                    <!-- <img class="chatImage" src="assets/img/pdf-logo.jpg" alt="" srcset=""> -->
                                                                    <object
                                                                        [data]="_sanitizer.bypassSecurityTrustResourceUrl(item?.message)"
                                                                        type="application/pdf" width="100%"
                                                                        height="100%">
                                                                        <iframe
                                                                            [src]="_sanitizer.bypassSecurityTrustResourceUrl(item?.message)"
                                                                            width="100%" height="100%"
                                                                            style="border: none;">
                                                                            <p>
                                                                                Your browser does not support PDFs.
                                                                                <a [href]="item?.message">Download the
                                                                                    PDF</a>
                                                                                .
                                                                            </p>
                                                                        </iframe>
                                                                    </object>
                                                                </span>
                                                                <span *ngIf="item?.messageType == 'url'">
                                                                    <a [href]="item?.message" target="_blank"
                                                                        rel="noopener noreferrer">{{item?.message}}</a>
                                                                </span>
                                                                <hr>
                                                                <span class="d-flex justify-content-center">
                                                                    <a [href]="item?.message" class="viewButton"
                                                                        target="_blank" download="FCG">
                                                                        <button *ngIf="item?.messageType != 'url'"
                                                                            class="btn btn-theme">View
                                                                            {{item?.messageType}}</button>
                                                                        <button *ngIf="item?.messageType == 'url'"
                                                                            class="btn btn-theme">Vist Link</button>
                                                                    </a>

                                                                </span>

                                                                <br>
                                                                <br>
                                                                <small class="messageTime"
                                                                    style="float: right;">{{item?.createdAt
                                                                    | date : 'MMM dd,
                                                                    yyyy h:mm a'}}
                                                                </small>

                                                            </span>

                                                        </span>
                                                    </p>


                                                </div>


                                            </li>

                                        </ng-template>


                                    </ul>
                                </div>
                                <div class="message-input">
                                    <div class="wrap row">
                                        <!-- <input type="text" placeholder="Write your message..." /> -->
                                        <!-- (keyup.enter)="pushMessage()" -->
                                        <textarea class="form-control reply-box"
                                            (keypress)="service.preventSpace($event)" [(ngModel)]="myMessage"
                                            maxlength="750" placeholder="Write your message..."></textarea>



                                        <button class="submit ml-2" (click)="openGalery()"><i class="fa fa-file-o"
                                                aria-hidden="true"></i></button>
                                        <button class="submit ml-2" (click)="pushMessage()"><i class="fa fa-paper-plane"
                                                aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>








                    </div>

                </div>
            </div>
        </div>

        <!-- tab genral end -->


        <!-- tab login session end -->

    </div>
</main>
<!-- delete_modal Start -->
<div class="modal fade dull-background global-modal reset-modal" id="mediaModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h6 class="elipisis" style="text-align: center; ">{{fileName}}</h6>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div *ngIf="fileType == 'image'" class="w-100 d-contents">
                                <img [src]="fileLink" class="media" alt="">
                            </div>
                            <div *ngIf="fileType == 'video'" class="w-100 d-contents">
                                <video *ngIf="fileLink" width="500" height="260" controls>
                                    <source [src]="fileLink" type="video/mp4">

                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div *ngIf="fileType == 'application'" class="w-100">
                                <object [data]="fileLink" type="application/pdf" width="100%" height="200px">
                                    <iframe [src]="fileLink" width="100%" height="100%" style="border: none;">
                                        <p>
                                            Your browser does not support PDFs.
                                            <a [href]="fileLink">Download the PDF</a>
                                            .
                                        </p>
                                    </iframe>
                                </object>

                            </div>
                            <!-- <div>
                                <button type="submit" class="btn btn-info mr-2">Yes</button>
                                <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                            </div> -->

                        </div>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn-theme"
                            (click)="uploadImageFunc(selectedFile)">Send</button>
                        <button type="button" class="btn btn-theme btn-cancel" (click)="closeModal()">Close</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->
<!-- <footer style="margin-top: 8%;">
    <div class="container-fluid">
        <p class="copyright">Blockchain Project Development <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer> -->