import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-wallet-management',
  templateUrl: './wallet-management.component.html',
  styleUrls: ['./wallet-management.component.css']
})
export class WalletManagementComponent implements OnInit {
  @Input() userId: any
  userForm: FormGroup;
  currTab: any ;
  withdrawlData: any = [];
  pageNumber: number = 1;
  depositeData: any = [];
  coin: any;
  coinName: any;
  coinData: any = [];
  url: string;
  walletBalance: any = [];
  id: any;
  totalCount: any
  itemPerPage : any = 10
  constructor(private router: Router, public service: MainService, private route: ActivatedRoute) {
    
    this.route.params.subscribe((params) => {
      this.id = params.id

    })

  }

  async ngOnInit() {
    console.log(this.userId);
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'select': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
    });
    await this.getWalletAddress()
    this.coinList()
    // this.withdrawl();

    this.userTrading();
    $(document).mousemove(function (e) {
      var cpos = { top: e.pageY + 10, left: e.pageX + 10 };
      $('#besideMouse').offset(cpos);
    });
    this.selectTab('Deposit')
    // this.customCursor()
  }
  loadingBM() {

    $("#besideMouse").html("click to copy");
    // alert(0)
  }
  stoploadingBM() {
    $("#besideMouse").html("");

  }
  // customCursor(){
  //   function loadingBM() {

  //     $("#besideMouse").html("click to copy");
  //     alert(0)
  //   }
  //   function stoploadingBM() {
  //     $("#besideMouse").html("");

  //   }


  //   $(document).ready(function () {
  //     $(document).mousemove(function (e) {
  //       var cpos = { top: e.pageY + 10, left: e.pageX + 10 };
  //       $('#besideMouse').offset(cpos);
  //     });
  //     $('#copyAddress').mouseenter(loadingBM);
  //     $('#copyAddress').mouseleave(stoploadingBM);
  //   });
  // }
  selectTab(tab) {
    this.currTab = tab;
    this.userForm.reset()
    this.userForm.patchValue({
      select : ''
    })
    this.pageNumber = 1
    this.totalCount = 0
    if (this.currTab === 'Withdraw') {
      this.withdrawl();
    }
    else if (this.currTab === 'Deposit') {
      this.deposite();
    }
    else {
      this.buy_sell()
    }
    // else if (this.currTab === 'BUY') {
    //   this.buy();
    // }
    // else if (this.currTab === 'SELL') {
    //   this.sell();
    // }
   

  }

  coinList() {
    var url = "wallet/coin/get-coin-list?page=" + (this.pageNumber - 1) + "&pageSize=10";
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      console.log('df', res);
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.coinData = res['data']


      }

    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })

  }

  // Api integration of withdrawl
  withdrawl() {
    var url = "wallet/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&txnType=WITHDRAW" + "&fkUserId=" + this.userId;
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      console.log('df', res);
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.withdrawlData = res['data']['resultlist'];

        this.totalCount = res.data.totalCount

      }

    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
        this.service.toasterErr('Something Went Wrong');
      }
    })
    this.service.showSpinner();
  }

  // Api integration of deposite
  deposite() {
    var url = "wallet/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&txnType=DEPOSIT" + "&fkUserId=" + this.userId;
    this.service.showSpinner();
    this.service.get(url).subscribe((res : any) => {
      console.log('df', res);
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.depositeData = res['data']['resultlist']
        this.totalCount = res.data.totalCount
      }

    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
    this.service.hideSpinner();
  }
  buySellData: any = []
  buy_sell() {
    var url = "wallet/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&txnType=" + String(this.currTab).toUpperCase() + "&fkUserId=" + this.userId;
    this.service.showSpinner();
    this.service.get(url).subscribe((res : any) => {
      console.log('df', res);
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.buySellData = res['data']['resultlist']
        this.totalCount = res.data.totalCount
      }

    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
    this.service.hideSpinner();
  }

  changePage(e){
    this.pageNumber = e
    if(this.isSearched){
      if (this.currTab === 'Withdraw') {
        this.withdrawl();
      }
      else if (this.currTab === 'Deposit') {
        this.deposite();
      }
      else {
        this.buy_sell()
      }
    }
    else{
      this.search()
    }
   
  }

  // Api of search 
  isSearched : boolean = false
  search() {
    this.isSearched =true
    this.coinName = this.userForm.value.select;
    // let fromDate=Date.parse(this.userForm.value.startdate);
    let fromDate = Date.parse(this.userForm.value.startdate);


    let toDate = Date.parse(this.userForm.value.enddate);

    let url = `wallet/get-all-transaction-history?page=${(this.pageNumber - 1)}&pageSize=10${this.coinName ? '&coinName=' + this.coinName : ''}${fromDate ? '&fromDate=' + fromDate : ''}${toDate ? '&toDate=' + toDate : ''}${this.currTab ? '&txnType=' + String(this.currTab).toUpperCase() : ''}`







    this.service.get(url).subscribe((res: any) => {
      console.log('search', res);

      if (res['status'] == 200) {
        this.service.toasterSucc(res.message);
        if (this.currTab === 'Withdraw') {
          this.withdrawlData = res['data']['resultlist']
        }
        else if (this.currTab === 'Deposit') {
          this.depositeData = res['data']['resultlist']

        }
        else{
          this.buySellData = res['data']['resultlist']
        }
        this.totalCount = res.data.totalCount

      }

    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
    this.service.hideSpinner();
  }

  // reset data of search
  reset() {
    this.isSearched =false
    if (this.currTab === 'Withdraw') {
      this.withdrawl();
      this.userForm.reset()
    this.userForm.patchValue({
      select : ''
    })
    }
    else if (this.currTab === 'Deposit') {
      this.deposite();
      this.userForm.reset()
    this.userForm.patchValue({
      select : ''
    })
    }
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    if (this.currTab === 'Withdraw') {
      this.withdrawlData.forEach((element, ind) => {

        dataArr.push({
          "S no": ind + 1,
          "Coin": element.coinType ? element.coinType : '',
          "Transaction Type": element.txnType ? element.txnType : '',
          "Email": element.userEmail ? element.userEmail : 'N/A',
          "Transaction Hash": element.txnHash ? element.txnHash : 'N/A',
          "Amount": element.amount ? element.amount : 'N/A',
          "Date": element.txnTime ? element.txnTime.slice(0, 10) : 'N/A',
        })
      })

      this.service.exportAsExcelFile(dataArr, 'WALLET MANAGEMENT');
    }
    
    else if (this.currTab === 'Deposit') {
      this.depositeData.forEach((element, ind) => {

        dataArr.push({
          "S no": ind + 1,
          "Coin": element.coinType ? element.coinType : '',
          "Transaction Type": element.txnType ? element.txnType : '',
          // "Email": element.userEmail ? element.userEmail : 'N/A',
          "Transaction Hash": element.txnHash ? element.txnHash : 'N/A',
          "Amount": element.amount ? element.amount : 'N/A',
          "Status": element.status ? element.status : 'N/A',
          "Date": element.txnTime ? element.txnTime.slice(0, 10) : 'N/A',
        })
      })

      this.service.exportAsExcelFile(dataArr, 'WALLET MANAGEMENT');

    }
    else{
      this.buySellData.forEach((element, ind) => {

        dataArr.push({
          "S no": ind + 1,
          "Coin": element.coinType ? element.coinType : '',
          "Transaction Type": element.txnType ? element.txnType : '',
          // "Email": element.userEmail ? element.userEmail : 'N/A',
          "Transaction Hash": element.txnHash ? element.txnHash : 'N/A',
          "Amount": element.amount ? element.amount : 'N/A',
          "Status": element.status ? element.status : 'N/A',
          "Date": element.txnTime ? element.txnTime.slice(0, 10) : 'N/A',
        })
      })

      this.service.exportAsExcelFile(dataArr, 'WALLET MANAGEMENT');
    }
  }



  // wallet balance
  // api of Trading
  userTrading() {
    var url = "wallet/wallet/get-all-user-balance-and-coinlist-withName?userId=" + this.id
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        //  this.walletBalance = res['data']['userBalance']
        let coin = ['BTC', 'ETH', 'USDT', 'GREM', 'GRET']
        for (let item of res['data']['userBalance']) {
          if (coin.includes(item.instrument)) {
            let temp = {
              "coinName": item.instrument,
              'balance': item.totalBalance,
              'wallet_address': this.getAddress(item.instrument) || '--'
            }
            this.walletBalance.push(temp)
          }
        }
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  getAddress(coinName) {
    let temp = this.userWalletAddress.find((ele) => {
      return coinName == ele.coinName
    })

    return temp.cryptoAddress
  }
  userWalletAddress: any = []
  async getWalletAddress() {
    let url = `wallet/wallet/get-all-user-address-for-admin?fkUserId=${this.userId}`
    this.service.showSpinner();
    try {
      let res: any = await this.service.get(url).toPromise()
      if (res['status'] == 200) {
        this.userWalletAddress = res.data

      } else {
        this.service.toasterErr(res['message']);
      }
    } catch (err) {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    }

  }
  copyText(text) {
    navigator.clipboard.writeText(text)
    this.service.toasterSucc('Wallet address copied');

  }
}
