import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/provider/main.service';
import { Router } from '@angular/router';
declare var $: any
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  Obj: { 'email': any; };
  showPassOrText: boolean;
  showEyeOrCrossEye: boolean;
  qrCode: any = '';

  constructor(
    private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    public service: MainService
  ) { }

  ngOnInit() {
    this.checkInput()
  }

  checkInput() {
    let remData = JSON.parse(localStorage.getItem('rememberMe')) ? JSON.parse(localStorage.getItem('rememberMe')) : '';
    let pass = ''
    if (remData != '') {
      pass = window.atob(remData.password)
    }

    this.loginForm = this.fb.group({
      email: [remData.email, [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)]],
      password: [pass, [Validators.required]],
      rememberMe: (false)

    })
  }



  onLogin() {
    
    this.service.showSpinner();
    this.service.post("auth", {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      deviceType: "WEB"
    }).subscribe(
      (res: any) => {

        this.service.hideSpinner();
        if (res['status'] == '200') {

     

          if (this.loginForm.value.rememberMe == true) {
            let remData = {
              "email": this.loginForm.value.email,
              "password": window.btoa(this.loginForm.value.password)
            }
            localStorage.setItem('rememberMe', JSON.stringify(remData))

          }
          // if(res['data']['TwoFa'] == "GOOGLE"){
          //   $('#googleAuth').modal({ backdrop: 'static' });
          //   localStorage.setItem('Auth', res['data']['token']);
          // }
          // else{
          //   localStorage.setItem('Auth', res['data']['token']);
          //   this.service.changeLoginSub('login');
          //   this.router.navigate(['dashboard']);
          // }


          this.service.toasterSucc("Login Successfully ")
          this.twoFAStatus = res['data']['TwoFa'];
          if (res['data']['TwoFa'] == 'NONE') {
            localStorage.setItem('Auth', res['data']['token']);

            $('#suggest').modal({ backdrop: 'static' });
            this.service.changeLoginSub('login');
            this.router.navigate(['dashboard']);
          window.location.reload()

          }
          else if (res['data']['TwoFa'] == 'GOOGLE') {
            localStorage.setItem('Auth', res['data']['token']);
            $('#googleAuth').modal({ backdrop: 'static' });
          } else if (res['data']['TwoFa'] == 'SMS') {
            localStorage.setItem('Auth', res['data']['token']);
            $('#smsAuth').modal({ backdrop: 'static' });
            this.suggesstionFunc('sms')
          } else if (res['data']['TwoFa'] == 'SKIP') {
            this.service.changeLoginSub('login');
            localStorage.setItem('credential', res['data']['token']);
            this.router.navigate(['dashboard']);
          window.location.reload()

          }

          // window.location.reload()

        }
        else {
          this.service.hideSpinner();
          this.service.toasterErr(res.message)
        }
      },
      (err: any) => {

        this.service.hideSpinner();
        if (err['status'] == '401') {
          this.service.toasterErr('Please Enter Correct Credentials');
        } else {
          this.service.toasterErr('Something Went Wrong');
        }
      }
    )
    this.Obj = {
      'email': this.loginForm.value.email,

    }

    localStorage.setItem('data', JSON.stringify(this.Obj));

  }
  showPassword() {
    this.showPassOrText = !this.showPassOrText;
    this.showEyeOrCrossEye = !this.showEyeOrCrossEye
  }

  // to remove token
  removeToken() {
    localStorage.removeItem('authToken')
  }

  // 2FA Suggestion Modal Functionality
  secretKey: any = '';
  googleAuth: any = '';
  twoFAStatus = 'GOOGLE'
  suggesstionFunc(action) {
    // if (action == 'Skip') {
    //   this.service.showSpinner();
    //   this.service.getCandyPixelForms('account/skip-twoFa').subscribe(res => {
    //     this.service.hideSpinner();
    //     if (res['status'] == 200) {
    //       this.toaster.success('Logged In');
    //       $('#suggest').modal('hide');
    //       this.service.changeLoginSub('login');
    //       let credential = localStorage.getItem('authToken')
    //       localStorage.removeItem('authToken')
    //       localStorage.setItem('credential', credential);
    //       this.router.navigate(['']);
    //     }
    //     else {
    //       this.toaster.error(res['message'])
    //     }
    //   }, err => {
    //     this.service.hideSpinner();
    //   })
    // }
    // else if (action == 'Google') {
    if (this.twoFAStatus == 'GOOGLE') {
      $('#suggest').modal('hide');
      $('#googleAuth').modal('show');
    }
    else {
      this.service.showSpinner();
      this.service.get('account/google-auth').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.qrCode = res['data']['qrCode'];
          this.secretKey = res['data']['secretKey'];
          $('#suggest').modal('hide');
          $('#googleAuth').modal('show');
        }
      }, err => {
        this.service.hideSpinner();
      })
    }
    // }
    // else if (action == 'sms') {
    //   if (this.twoFAStatus == 'SMS') {
    //     this.service.showSpinner();
    //     this.service.getCandyPixelForms('auth/send-sms-code').subscribe(res => {
    //       this.service.hideSpinner();
    //       if (res['status'] == 200) {
    //         $('#suggest').modal('hide');
    //         $('#smsAuth').modal('show');
    //         this.toaster.success(res['message']);
    //       }
    //       else {
    //         this.toaster.error(res['message']);
    //       }
    //     }, err => {
    //       this.service.hideSpinner();
    //       if (err['status'] == '500') {
    //         this.toaster.error('Mobile number is not verified');
    //       }
    //       else {
    //         this.toaster.error(err['message']);
    //       }
    //     })
    //   } else {
    //     this.service.showSpinner();
    //     this.service.getCandyPixelForms('account/send-sms-code').subscribe(res => {
    //       this.service.hideSpinner();
    //       if (res['status'] == 200) {
    //         $('#suggest').modal('hide');
    //         $('#smsAuth').modal('show');
    //         this.toaster.success(res['message']);
    //       }
    //       else {
    //         this.toaster.error(res['message']);
    //       }
    //     }, err => {
    //       this.service.hideSpinner();
    //       if (err['status'] == '500') {
    //         this.toaster.error('Mobile number is not verified');
    //       }
    //       else {
    //         this.toaster.error(err['message']);
    //       }
    //     })
    //   }
    // }
  }
  // Verify Google / SMS Auth Functionality By Accounts
  verifyGoogleAuth(select) {
    if (select == 'Google') {
      var apireq = {};
      var url = '';
      if (this.twoFAStatus == 'GOOGLE') {
        url = 'auth/verify-google';
        apireq = {
          "otp": this.googleAuth,
          //"secretKey": this.secretKey
        }
      } else {
        url = 'account/verify-google-code';
        apireq = {
          "code": Number(this.googleAuth),
          "secretKey": this.secretKey,
          "ipAddress": this.ipAddress,
          "source": "WEB"
        }
      }
      this.service.showSpinner();
      this.service.postApi(url, apireq).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          // localStorage.removeItem('Auth')
          if (this.twoFAStatus == 'GOOGLE') {
            this.service.changeLoginSub('login');
            localStorage.setItem('Auth', res['data']);
            sessionStorage.setItem('secretKey', this.secretKey);
          }
          this.service.toasterSucc('Google Authentication Verified');
          $('#googleAuth').modal('hide');
          this.router.navigate(['dashboard']);
          window.location.reload()

        }
        else {
          this.service.toasterErr('Wrong Google Authentication Code');
          this.googleAuth = ''
        }
      }, err => {
        this.service.hideSpinner();
        this.service.toasterErr('Something Went Wrong');
      })
    }
    // else if (select == 'sms') {
    //   var smsapireq = {};
    //   var url = '';
    //   if (this.twoFAStatus == 'SMS') {
    //     url = 'auth/verify-sms';
    //     smsapireq = {
    //       'code': this.smsAuth
    //     }
    //   }
    //   else {
    //     url = 'account/verify-sms-code';
    //     smsapireq = {
    //       'code': this.smsAuth,
    //       'ipAddress': this.ipAddress,
    //       'source': 'WEB'
    //     }
    //   }
    //   this.service.showSpinner();
    //   this.service.postCandyPixelForms(url, smsapireq).subscribe(res => {
    //     this.service.hideSpinner();
    //     if (res['status'] == 200) {
    //       $('#smsAuth').modal('hide');
    //       this.service.changeLoginSub('login');
    //       //localStorage.removeItem('authToken')
    //       if (this.twoFAStatus == 'SMS')
    //         localStorage.setItem('credential', res['data']);
    //       this.toaster.success(res['message']);
    //       this.router.navigate(['']);
    //     }
    //     else {
    //       this.toaster.error(res['message']);
    //     }
    //   }, err => {
    //     this.service.hideSpinner();
    //     this.toaster.error('Something Went Wrong');
    //   })
    // }
  }
  copyToClipboard(item) {
    navigator.clipboard.writeText(item);
  }
  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }
  ipAddress: string
  userLocationCurrency: any
  getIP() {
    this.service.getIPAddress().subscribe((res: any) => {
      // console.log(res)
      this.ipAddress = res.ip;
      if (this.ipAddress) {
        return this.locationViaIP()
      }
    });
  }
  /**
   * get location and other details via ip address of current system logged in user
   * @param ipAddress from above getIP function
   */
  locationViaIP() {
    this.service.getLocationViaIP(this.ipAddress).subscribe((res: any) => {
      // console.log(res)
      if (res) {
        this.userLocationCurrency = res.currency.code
        // console.log(this.userLocationCurrency)
        localStorage.setItem('userLocationCurrency', this.userLocationCurrency)
        return this.userLocationCurrency
      }
    });
  }

}

