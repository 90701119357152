


<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">ABOUT US</h1>
            <hr>
        </div>

        <div class="content-section">
            <div class="order-view mt30 max-WT-800 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">



                        <div class="form-group row ">
                            <label class="col-md-4">Page Name :</label>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <textarea class="form-control common-textarea" [(ngModel)]="title"></textarea>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Description :</label>

                        </div>
                        <div class="form-group row">
                            <div  class="col-md-12">

                                <ck-editor name="editor1" skin="moono-lisa" language="en" [(ngModel)]="description" [fullPage]="true"></ck-editor>

                            </div>
                        </div>
                        <div class="text-center mt40" style="margin-right: -2%;">
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-grey "
                            [routerLink]="['/static-content-management']">Cancel</a>
                            <a  class="btn btn-large  max-WT-200 font-100 btn-green ml-4"
                                (click)="addStatic()" >Add</a>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </main>
</div>
