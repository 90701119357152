
<div class="outer-box">
    <div class="global-table no-radius p0">
        <div class="tab-content1">
            <div class="tab-pane1">
                <div class="table-responsive">
                    <table class="table table-bordered" aria-describedby="user list">
                        <thead>
                            <tr class="no_wrap_th">
                                <th scope="col">S.No</th>
                                <th scope="col">Withrawl Fee</th>
                                <th scope="col">Coin name</th>
                                <th scope="col">Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let item of coinList  | paginate:{itemsPerPage:10, currentPage:pageNumber, totalItems:coinList.length?.length}; let i=index">
                                <td>{{i+1}}</td>
                                <td *ngIf="!cointAmount[i]">{{item.withdrawlFee}}</td>
                                <td *ngIf="cointAmount[i]">
                                    <input [value]="item.withdrawlFee"
                                        [(ngModel)]="withdrawAmount[i] = item.withdrawlFee"
                                        (keypress)="service.preventSpace($event)"
                                        style="text-align: left;" maxlength="6" type="number" placeholder="Withdrawl Fee"
                                        class="form-control">
                                </td>

                                <td>{{item.coinShortName == 'AVT' ? 'GREM' : item.coinShortName}}</td>
                                <td>
                                    <button *ngIf="!cointAmount[i]" type="submit" class="btn  btn-theme cus-mr"
                                        (click)="enableInputField(i)" style="width: 88%;">Edit</button>
                                    <button *ngIf="cointAmount[i]" type="submit" class="btn  btn-theme cus-mr"
                                        (click)="updateCoinAmount(item.coinShortName,withdrawAmount[i])"
                                        style="width: 88%;">Update</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
              
            </div>
        </div>
    </div>
</div>
<!-- </div>
</main> -->

