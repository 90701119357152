<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:#26272d">DASHBOARD</h1>
        </div>
        <div class="content-section dashboard-block">
            <ul class="dash_list d-flex w-100 flex-wrap text-center ">
                <li class="d-inline-flex align-items-center justify-content-center bg-blue" style="cursor: pointer;"
                    routerLink="/user-management">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-users fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Registered Users</h2>
                        <h4>{{TotalUserCount || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-green" style="cursor: pointer;"
                    routerLink="/user-management">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-user-ninja fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Active Users</h2>
                        <h4>{{activeUserLength || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;"
                    routerLink="/user-management">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-user-slash fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Blocked Users</h2>
                        <h4>{{blockedUserLength || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue" style="cursor: pointer;"
                    routerLink="/kyc-update">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-file fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Pending KYC</h2>
                        <h4>{{kycpendingListlength || 0}}</h4>
                    </div>
                </li>
                <!-- <li class="d-inline-flex align-items-center justify-content-center bg-green" style="cursor: pointer;"
                    routerLink="/hot-wallet-management">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-users-cog fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Deposit Request</h2>
                        <h4>{{totalDepositeCount}}</h4>
                    </div>
                </li> -->
                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;"
                    routerLink="/hot-wallet-management">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-coins fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Coins</h2>
                        <h4>{{(totalCoinCount + totaltokenCount) || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;"
                    routerLink="/vendor-list">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-users fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Vendor</h2>
                        <h4>{{totalVendor || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;"
                    routerLink="/vendor-list">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-user-plus fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Active Vendor</h2>
                        <h4>{{totalActiveVandor || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-red"
                    [routerLink]="['/dividened-management']" style="cursor: pointer;">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-coins fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>{{service.davidant | uppercase}}</h2>
                        <h4>{{0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fa fa-btc fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>BTC</h2>
                        <div class="row justify-content-center">
                            <h6>Admin</h6>
                            <p class="ml-1">:</p>
                            <p class="ml-1">{{toFixed(adminHotWallet?.BTC,7) || 0}}</p> &nbsp; &nbsp;
                            <h6>User</h6>
                            <p class="ml-1">:</p>
                            <p class="ml-1">{{toFixed(totalUserwallet?.BTC,5) || 0}}</p>
                        </div>

                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fab fa-ethereum fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>ETH</h2>
                        <div class="row justify-content-center">
                            <h6>Admin</h6>
                            <p class="ml-1">:</p>
                            <p class="ml-1">{{toFixed(adminHotWallet?.ETH,7) || 0}}</p> &nbsp; &nbsp;
                            <h6>User</h6>
                            <p class="ml-1">:</p>
                            <p class="ml-1">{{toFixed(totalUserwallet?.ETH,5) || 0}}</p>
                        </div>
                    </div>
                </li>
                <li  (mouseenter)="changeImage = true" (mouseleave)="changeImage = false" class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;">
                    <div class="w-100">
                        <div class="dash_icn">
                            <!-- <i class="fa fas fa-lira-sign fa-3x" aria-hidden="true"></i> -->
                            <img *ngIf="!changeImage" src="assets/img/tether-white.png" width="42px">
                            <img *ngIf="changeImage" src="assets/img/tether-blue.png" width="42px">
                        </div>
                        <h2>USDT

                        </h2>
                        <div class="row justify-content-center">
                            <h6>Admin</h6>
                            <p class="ml-1">:</p>
                            <p class="ml-1">{{toFixed(adminHotWallet?.USDT,7) || 0}}</p> &nbsp; &nbsp;
                            <h6>User</h6>
                            <p class="ml-1">:</p>
                            <p class="ml-1">{{toFixed(totalUserwallet?.USDT,5)  || 0}}</p>
                        </div>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas  fa-3x" aria-hidden="true">GREM</i>
                        </div>
                        <h2>GREM

                        </h2>
                        <div class="row justify-content-center">
                            <h6>Admin</h6>
                            <p class="ml-1">:</p>
                            <p class="ml-1">{{toFixed(adminHotWallet?.GREM,7) || 0}}</p> &nbsp; &nbsp;
                            <h6>User</h6>
                            <p class="ml-1">:</p>
                            <p class="ml-1">{{toFixed(totalUserwallet?.GREM,5)  || 0}}</p>
                        </div>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas  fa-3x" aria-hidden="true">GRET</i>
                        </div>
                        <h2>GRET

                        </h2>
                        <div class="row justify-content-center">
                            <h6>Admin</h6>
                            <p class="ml-1">:</p>
                            <p class="ml-1">{{toFixed(adminHotWallet?.GRET,7) || 0}}</p> &nbsp; &nbsp;
                            <h6>User</h6>
                            <p class="ml-1">:</p>
                            <p class="ml-1">{{toFixed(totalUserwallet?.GRET,5) || 0}}</p>
                        </div>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas  fa-2x" aria-hidden="true">GRET {{service.davidant | uppercase}}</i>
                        </div>
                        <!-- <h2>
                            GRET

                        </h2> -->
                        <div class="row justify-content-center" style="margin-top: 30px;">
                            <!-- <div class="col-md-12 d-flex h-20">
                                <b>USD</b>
                                <span>:</span>
                                <p class="ml-1">0.115</p>

                            </div>
                            <div class="col-md-12 d-flex h-20">
                                <b>USDT</b>
                                <span class="ml-1">:</span> 
                                <p class="ml-1">0.115</p>

                            </div> -->
                            <div class="col-md-12 d-flex " >
                                <b style="font-size: 10px">EXPECTED DIVIDEND DUE FOR CURRENT MONTH</b>
                                

                            </div>
                            
                            <div class="col-md-12 d-flex">
                                <b style="font-size: 16px">Total GRET</b>
                                <span class="ml-1">:</span>
                                <p class="ml-1" style="font-size: 16px">{{gretDavidant}}</p>

                            </div>
                           
                        </div>
                    </div>
                </li>

                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas  fa-2x" aria-hidden="true">GREM {{service.davidant | uppercase}}</i>
                        </div>
                        <!-- <h2>
                            GRET

                        </h2> -->
                        <div class="row justify-content-center" style="margin-top: 30px;">
                            <!-- <div class="col-md-12 d-flex h-20">
                                <b>USD</b>
                                <span>:</span>
                                <p class="ml-1">0.115</p>

                            </div>
                            <div class="col-md-12 d-flex h-20">
                                <b>USDT</b>
                                <span class="ml-1">:</span>
                                <p class="ml-1">0.115</p>

                            </div> -->
                            <div class="col-md-12 d-flex " >
                                <b style="font-size: 10px">EXPECTED DIVIDEND DUE FOR CURRENT YEAR</b>
                                

                            </div>
                            
                            <div class="col-md-12 d-flex">
                                <b style="font-size: 16px">Total GREM</b>
                                <span class="ml-1">:</span>
                                <p class="ml-1" style="font-size: 16px">{{gremDavidant}}</p>

                            </div>
                           
                        </div>
                    </div>
                </li>

                <!-- <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;"
                    routerLink="/token-management">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-coins fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Token Registered</h2>
                        <h4>{{totaltokenCount}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;"
                    >
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-coins fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Deviant</h2>
                        <h4>{{0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;"></li>
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-coins fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Commision Earning</h2>
                        <h4>{{0}}</h4>
                    </div>
                </li> -->
            </ul>
        </div>
    </main>
</div>