<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">View Faq</h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <form>
                            <div class="form-group row ">
                                <label class="col-md-4">Question :</label>
                                <div class="col-md-8 col-sm-12">
                                    <p class="word-break">{{editData?.question}}</p>

                                    
                                </div>

                            </div>
                            <div class="form-group row">

                                <label class="col-md-4">Answer:</label>
                                <div class="col-md-8 col-sm-12">
                                    <p class="word-break">{{editData?.answer}}</p>

                                </div>
                            </div>
                            <div class="text-center mt40">

                                <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-grey"
                                    [routerLink]="['/faq-management']">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

