<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#244273" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<app-sidebar ></app-sidebar>
<router-outlet></router-outlet>
<footer class="footer">
  <div class="container-fluid">
      <p class="copyright">GLOBAL REIT © 2022 All Rights Reserved. <strong class="theme-text-color"></strong>
      </p>
  </div>
</footer>



     