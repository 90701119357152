<div class="outer-box">
    <div class="global-table no-radius p0">
        <div class="tab-content1">
            <div class="tab-pane1">

             
                <div class=" sec_head_new_after">

                  
                    <form>
                        <div class="row form-group">
                          

                            <div class="col-md-3 col-sm-12 user-lst">
                                <div class="">
                                    <div class="text-left">
                                        <input type="text" [(ngModel)]="searchText"
                                            [ngModelOptions]="{standalone: true}" placeholder="Email"
                                            class="form-control form ">
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-7 row col-sm-6 user-lst">
                                <div class="two-btnline">
                                    <div class="text-left" style="margin-left: 1%" (click)="kyc()">
                                        <button type="submit" class="btn  btn-theme cus-mr">Search</button>
                                    </div>
                                </div>
                                <div class="two-btnline sm-left">
                                    <div class="text-left" style="margin-left: 5px" (click)="pageNumber = 1 ; reset()">
                                        <button type="submit" class="btn  btn-theme cus-mr">Reset</button>
                                    </div>
                                </div>
                            </div>
                           
                            

                            <div class="col-md-2 col-sm-12">

                                <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                                </button>
                            </div>
                        </div>

                    </form>
                </div>


                <div class="table-responsive">
                    <table class="table table-bordered" aria-describedby="user list">
                        <thead>
                            <tr class="no_wrap_th">
                                <th scope="col">S.No</th>
                                <th scope="col">Vendor Name</th>
                                <th scope="col">Vendor Email</th>
                                <th scope="col">GRET Amount</th>
                                <th scope="col">Txn. Id</th>

                                <th scope="col">Status</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let data of vendorList | paginate:{itemsPerPage:20, currentPage:pageNumber, totalItems:vendorList?.length}; let i=index">
                                <td>{{20*(this.pageNumber-1)+i+1}}</td>
                                <td>{{data?.userName || 'N/A'}}</td>
                                <td>{{data?.userEmail || 'N/A'}}</td>
                                <td>{{data?.amount || 'N/A'}}</td>

                                <td>{{data?.txnId || 'N/A'}}</td>
                                <td>{{data?.status || 'N/A'}}</td>
                                <td>{{data?.txnTime | date : 'medium' || 'N/A'}}</td>

                            </tr>
                            <tr *ngIf="vendorList?.length==0">
                                <td colspan="10" vertical-align="middle">
                                    <div class="no-record">
                                        <div class="no-recordin">
                                            <H5 style="margin-left: 10%;">No record found</H5>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end">
                    <pagination-controls (pageChange)="changePage($event)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 *ngIf="userStatus=='ACTIVE'" style="text-align: center; ">Unblock </h3>
                        <h3 *ngIf="userStatus=='BLOCK'" style="text-align: center; ">Block </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p *ngIf="userStatus=='ACTIVE'" style="font-size: 25px;">Are you sure you want to
                                    unblock?</p>
                                <p *ngIf="userStatus=='BLOCK'" style="font-size: 25px;">Are you sure you want to block?
                                </p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete Vendor </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>