import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-vendor-redemption-history',
  templateUrl: './vendor-redemption-history.component.html',
  styleUrls: ['./vendor-redemption-history.component.css']
})
export class VendorRedemptionHistoryComponent implements OnInit {
  kycForm: FormGroup;
  pageNumber: number = 1;
  vendorList: any = [];
  userId: any;
  url: string;
  text: any = false;
  searchText: any
  isSearch : boolean = false
  constructor(
    public route: Router,
    public service: MainService,
    public transform: DatePipe,
    private activated : ActivatedRoute
  ) { 
    this.activated.queryParams.subscribe((res) => {
      this.userId = res.id
    })
  }


  ngOnInit(): void {
    this.kyc();
    this.kycForm = new FormGroup({
      status: new FormControl("", Validators.required),
      userName: new FormControl("", Validators.required),
    });

  }

  // api of kyc listing
  kyc() {
    // this.vendorList = [ 
    //   {name : 'Tanveer Haider' , email : 'tanveer@mailinator.com',greatAdd : '4545',status : 'Active' }
    // ]

    var url = `wallet/get-all-transaction-history?txnType=USDTS&page=${this.pageNumber - 1}&pageSize=10&fkUserId=${this.userId}`
    this.service.get(url).subscribe(
      (res: any) => {
        console.log("kyc", res);
        if (res.status == 200) {
          this.vendorList = res.data.resultlist;
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  
  // Api of search
  search() {
    let kycStatus = this.kycForm.value.status;
    let search = this.kycForm.value.userName;
    if (this.kycForm.value.userName && this.kycForm.value.status) {
      this.url =
        "account/admin/kyc-management/filter-kyc-users-list?page=" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&kycStatus=" +
        kycStatus +
        "&search=" +
        search;
    } else if (this.kycForm.value.userName) {
      this.url =
        "account/admin/kyc-management/filter-kyc-users-list?page=" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&search=" +
        search;
    } else if (this.kycForm.value.status) {
      this.url =
        "account/admin/kyc-management/filter-kyc-users-list?page=" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&kycStatus=" +
        kycStatus;
    }
    this.service.get(this.url).subscribe(
      (res: any) => {
        console.log("search", res);

        if (res["status"] == 200) {
          this.vendorList = res.data.list;
          this.service.toasterSucc("User found successfully");
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  // reset
  reset() {
    this.searchText = ''
    this.isSearch = false
    this.pageNumber = 1
    this.kyc()
  }
  userid: any
  userStatus: any
  block(status, id) {
    this.userid = id;
    this.userStatus = status == 'ACTIVE' ? 'BLOCK' : 'ACTIVE';
    $("#block").modal("show");
  }
  blockUser() {
    /* this.service.showSpinner(); */
    var url =
      "account/admin/user-management/user-status?userStatus=" +
      this.userStatus +
      "&userId=" +
      this.userid;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          this.service.toasterSucc(res.message);
          /*  this.service.hideSpinner();  */ this.kyc();
          $("#block").modal("hide");
        }
      },
      (err) => {
        /*  this.service.hideSpinner();   */
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  User() {
    /* this.service.showSpinner(); */
    var url =
      "account/admin/user-management/user-status?userStatus=" +
      this.userStatus +
      "&userId=" +
      this.userid;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          this.service.toasterSucc(res.message);
          /*  this.service.hideSpinner();  */ this.kyc();
          $("#block").modal("hide");
        }
      },
      (err) => {
        /*  this.service.hideSpinner();   */
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

   //========modal=======//
   id : any
   delete(id: number) {
    this.id = id;
    $("#deleteModal").modal("show");
  }
  deleteUser() {
    this.service
      .get(`account/admin/user-management/delete-user-detail?userId=${this.id}`)
      .subscribe(
        (res: any) => {
         
          if ((res.status = 200)) {
            $("#deleteModal").modal("hide");
            this.service.toasterSucc(res.message);
            this.kyc();
          }
        },
        (err) => {
          /* this.service.hideSpinner();  */ if (err["status"] == "401") {
            this.service.onLogout();
            this.service.toasterErr("Unauthorized Access");
          } else {
            this.service.toasterErr("Something Went Wrong");
          }
        }
      );
  }
  changePage(e){
    this.pageNumber = e
    if(this.isSearch){
      this.search()
    }
    else{
      this.kyc()
    }
  }

  exportAsXLSX() {
    let dataArr = [];
    this.vendorList.forEach((element, ind) => {
      dataArr.push({
        "S.No": ind + 1,
        "Vendor Name": element.userName ? element.userName : "N/A",
        "Vendor Email": element.userEmail ? element.userEmail : "N/A",
        "GRET Amount": element.amount ? element.amount : "N/A",
        "Txn. Id": element.txnId ? element.txnId : "N/A",
        "Status": element.status ? element.status : "N/A",
        "Date": element.txnTime ? this.transform.transform(element.txnTime,'medium') : "N/A",
        
      });
    });
    console.log(dataArr);
    this.service.exportAsExcelFile(dataArr, "Vendor Redemption History");
  }


}
