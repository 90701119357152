<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">User Wallet</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="userForm">
                                <div class="row form-group">
                                    <div class="col-lg-2 col-md-2 col-sm-12">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">Coin Name </span>
                                                <select name="" id="" class="form-control form1"
                                                    formControlName="coinName">
                                                    <option value="">Select Coin Name</option>
                                                    <option value="BTC">BTC</option>
                                                    <option value="ETH">ETH</option>
                                                    <option value="USDT">USDT</option>
                                                    <option value="GRET">GRET</option>
                                                    <option value="GREM">GREM</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center op-0">Wallet address</span>
                                                <input type="text" class="form-control form"
                                                    placeholder="Search by wallet address" formControlName="address">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center op-0"
                                                    style="margin-left: 5px!important ">User Email</span>

                                                <input type="text" placeholder="Search by user email"
                                                    class="form-control form" (input)="clearFilter()"
                                                    formControlName="email">
                                            </div>
                                        </div>

                                    </div>

                                    <!-- <div class="col-lg-2 col-md-2 col-sm-12 op-0">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center op-0"> Search</span>
                                                <input type="text" class="form-control" placeholder="Search by wallet address"
                                                    formControlName="searchText">
                                            </div>
                                        </div>

                                    </div> -->
                                    <div class="col-lg-1 col-md-3 col-sm-6 pl-0 user-lst">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center op-0"> Search</span>
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    (click)="pageNumber = 1 ;search()">Search</button>
                                            </div>
                                        </div>




                                    </div>
                                    <div class="col-lg-1 col-md-3 col-sm-6 pl-0 user-lst">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label text-right input-label1">
                                                <span class="d-flex align-items-center op-0"> Search</span>
                                                <button type="submit" class="btn  btn-theme" (click)="reset()">Reset
                                                </button>
                                            </div>
                                        </div>




                                    </div>
                                    <div class="col-lg-1 col-md-3 col-sm-12 pl-0 user-lst">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label text-right input-label1">
                                                <span class="d-flex align-items-center op-0"> Search</span>
                                                <button type="submit" class="btn  btn-theme"
                                                    (click)="exportAsXLSX()">EXPORT AS EXCEL
                                                </button>
                                            </div>
                                        </div>




                                    </div>

                                </div>

                            </form>
                        </div>
                        <!-- <div class="row  d-flex justify-content-end w_100">

                            <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                            </button>
                        </div> -->


                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <!-- <th scope="col">Message</th> -->
                                        <th scope="col">Email Address</th>
                                        <th scope="col">User Name</th>
                                        <th scope="col">BTC</th>
                                        <th scope="col">ETH</th>
                                        <th scope="col">USDT</th>
                                        <th scope="col">GREM</th>
                                        <th scope="col">GRET</th>
                                        <!-- <th scope="col">Wallet Address</th> -->
                                        <!-- <th scope="col">Amount</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="new-hgt-tab" *ngFor="let item of filterList | paginate: { 
                                        itemsPerPage: limit,
                                        currentPage: pageNumber,
                                        totalItems: totalRecords}; let i=index">
                                        <td>{{limit*(this.pageNumber-1)+i+1}}</td>
                                        <!-- <td class="elips">{{item.description || 'N/A'}}</td> -->
                                        <td>{{item?.BTC?.email || 'N/A'}}</td>
                                        <td>{{item?.BTC?.userName || 'N/A'}}</td>
                                        <td>

                                            <div class="d-flex justify-content-center els44568">
                                                <p>{{item?.BTC?.walletAddress &&
                                                    service.sortAddress(item?.BTC?.walletAddress) || 'N/A'}}</p>
                                                &nbsp;<i *ngIf="item?.BTC?.walletAddress"
                                                    class="fa fa-clone copyPointer m4p"
                                                    (click)="service.copyToClipboard(item?.BTC?.walletAddress )"
                                                    aria-hidden="true"></i>
                                            </div>
                                            <div class="text-center">
                                                <p>BTC : {{item?.BTC?.walletBalance || 0}}</p>
                                            </div>

                                        </td>
                                        <td>

                                            <div class="d-flex justify-content-center els44568">
                                                <p>{{item?.ETH?.walletAddress &&
                                                    service.sortAddress(item?.ETH?.walletAddress) || 'N/A'}}</p>
                                                &nbsp;<i *ngIf="item?.ETH?.walletAddress"
                                                    class="fa fa-clone copyPointer m4p"
                                                    (click)="service.copyToClipboard(item?.ETH?.walletAddress)"
                                                    aria-hidden="true"></i>
                                            </div>
                                            <div class="text-center">
                                                <p>ETH : {{item?.ETH?.walletBalance || 0}}</p>
                                            </div>

                                        </td>
                                        <td>

                                            <div class="d-flex justify-content-center els44568">
                                                <p>{{item?.USDT?.walletAddress &&
                                                    service.sortAddress(item?.USDT?.walletAddress) || 'N/A'}}</p>
                                                &nbsp;<i *ngIf="item?.USDT?.walletAddress"
                                                    class="fa fa-clone copyPointer m4p"
                                                    (click)="service.copyToClipboard(item?.USDT?.walletAddress)"
                                                    aria-hidden="true"></i>
                                            </div>
                                            <div class="d-flex justify-content-center els44568">
                                                <p> USDT : {{item?.USDT?.walletBalance || 0}}</p>
                                                &nbsp;<i *ngIf="item?.USDT?.walletAddress"
                                                    (click)="updateBalance('USDT',item?.GRET.fkUserId,item?.USDT?.walletBalance )"
                                                    class="fa fa-pencil copyPointer m4p" aria-hidden="true"></i>
                                            </div>
                                            <!-- <div class="text-center">
                                                <p>USDT : {{item?.USDT?.walletBalance || 0}}</p>
                                            </div> -->
                                        </td>
                                        <td>

                                            <div class="d-flex justify-content-center els44568">
                                                <p>{{item?.GREM?.walletAddress &&
                                                    service.sortAddress(item?.GREM?.walletAddress) || '--'}}</p>
                                                &nbsp;<i *ngIf="item?.GREM?.walletAddress"
                                                    class="fa fa-clone copyPointer m4p"
                                                    (click)="service.copyToClipboard(item?.GREM?.walletAddress)"
                                                    aria-hidden="true"></i>
                                            </div>
                                            <div class="d-flex justify-content-center els44568">
                                                <p> GREM : {{item?.GREM?.walletBalance || 0}}</p>
                                                &nbsp;<i *ngIf="item?.GREM?.walletAddress"
                                                    (click)="updateBalance('GREM',item?.GRET.fkUserId,item?.GREM?.walletBalance )"
                                                    class="fa fa-pencil copyPointer m4p" aria-hidden="true"></i>
                                            </div>
                                            <!-- <div class="text-center">
                                                <p>GREM :  {{item?.GREM?.walletBalance || 0}}</p>
                                            </div> -->
                                        </td>
                                        <td>

                                            <div class="d-flex justify-content-center els44568">
                                                <p> {{item?.GREM?.walletAddress &&
                                                    service.sortAddress(item?.GREM?.walletAddress) || '--'}}</p>
                                                &nbsp;<i *ngIf="item?.GRET?.walletAddress"
                                                    class="fa fa-clone copyPointer m4p"
                                                    (click)="service.copyToClipboard(item?.GRET?.walletAddress )"
                                                    aria-hidden="true"></i>
                                            </div>
                                            <div class="d-flex justify-content-center els44568">
                                                <p>GRET : {{item?.GRET?.walletBalance || 0 }}</p>
                                                &nbsp;<i *ngIf="item?.GRET?.walletAddress"
                                                    (click)="updateBalance('GRET',item?.GRET.fkUserId,item?.GRET?.walletBalance )"
                                                    class="fa fa-pencil copyPointer m4p" aria-hidden="true"></i>
                                            </div>
                                            <!-- <div class="text-center">
                                                <p>GRET : {{item?.GRET?.walletBalance || 0 }}</p>
                                            </div> -->
                                        </td>
                                        <!-- <td> <div class="d-flex justify-content-center">
                                            <p class="elip">{{item.address || 'N/A'}}</p>
                                            &nbsp;<i *ngIf="item.address" class="fa fa-clone copyPointer m4p"
                                                (click)="service.copyToClipboard(item.address )"
                                                aria-hidden="true"></i>
                                        </div></td> -->
                                        <!-- <td>{{item.amount || '0'}}</td> -->


                                    </tr>
                                    <!--  -->
                                    <tr *ngIf="showSpinner && filterList?.length == 0">
                                        <td colspan="10" class="text-center" vertical-align="middle">
                                            <img class="loader-image" src="assets/img/loder.gif" alt="" srcset="">

                                        </td>
                                    </tr>
                                    <tr *ngIf="!showSpinner && filterList?.length==0">
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin-left: 10%;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>




<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="updateBalaneModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Update {{coinName}} Balance</h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <!-- <p style="font-size: 25px;">Are you sure you want to delete this user?</p> -->
                                <input type="number" class="form-control hhh" maxlength="15" [(ngModel)]="balance"
                                    placeholder="Enter wallet balance" [ngModelOptions]="{standalone: true}">
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="updateBalAPI()">Update</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Unblock </h3>
                        <h3 style="text-align: center; ">Block </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to unblock?</p>
                                <p style="font-size: 25px;">Are you sure you want to block?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>