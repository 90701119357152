<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">USER DETAIL</h1>
        </div>
        <div class="custom_tabs common-tabs">
            <div class="row mb20 justify-content-center">
                <div class="col-sm-12">
                    <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='GI'}" (click)="selectTab('GI')"
                                href="javascript:;">General Information</a>
                        </li>
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='WalletTrading'}" (click)="selectTab('WalletTrading')"
                                href="javascript:;">Wallet Trading</a>
                        </li>
                        <!-- <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='Trading'}" (click)="selectTab('Trading')"
                                href="javascript:;">Trading</a>
                        </li> -->
                        <!-- <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='Login'}" (click)="selectTab('Login')"
                                href="javascript:;">Login Session Activity</a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="currTab=='GI'" class="row justify-content-end">
            <div class="col-md-2" style="margin-right: 2%">
                <div class="head_flt_select input-label" style="margin-top: -2%">
                    <button type="submit" class="btn  btn-theme" 
                        (click)="userDetailsExportAsXLSX()">EXPORT AS EXCEL </button>

                </div>
            </div>
        </div> -->

        <!-- tab General -->
        <div class="tab-pane active show" *ngIf="this.currTab == 'GI'">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mbt10">
                    <div class="add-store-block input-style">
                        <div class="form-group row">
                            <div class="col-md-12 text-center">
                                
                                <img *ngIf="userDetail?.imageUrl == 'string'" [src]="'assets/img/profile-img.jpg'" alt="" style="    border-radius: 50%;">
                                <img *ngIf="userDetail?.imageUrl != 'string'" [src]="userDetail?.imageUrl || 'assets/img/profile-img.jpg'" alt="" style="    border-radius: 50%;">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName"><b>Name</b></label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.firstName}} {{userDetail?.lastName}}</p>
                            </div>

                            <!-- <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <label>{{userDetail?.firstName}} {{userDetail?.lastName}}</label>
                            </div> -->
                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName"><b>Email</b></label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.email || 'N/A'}}</p>
                            </div>

                            <!-- <label class="col-md-4"></label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <label class="user-eml"></label>
                            </div> -->
                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName"><b>Mobile Number</b></label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.phoneNo || 'N/A'}}</p>
                            </div>

                            <!-- <label class="col-md-4"></label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <label>{{userDetail?.phoneNo || 'N/A'}}</label>
                            </div> -->
                        </div>


                        <!-- <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName"><b>Two Factor Type</b></label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.twoFaType || 'N/A'}}</p>
                            </div> -->
                            <!-- <label class="col-md-4"></label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <label>{{userDetail?.twoFaType || 'N/A'}}</label>
                            </div> -->
                        <!-- </div> -->

                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName"><b>Country</b></label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.country || 'N/A'}}</p>
                            </div>
                            <!-- <label class="col-md-4">Address </label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <p class="rap2">{{userDetail?.address || 'N/A'}}</p>
                            </div> -->
                        </div>

                        <div class="form-group row" *ngIf="userDetail?.kyc?.document[0]?.documentStatus == 'ACCEPTED'">
                            <div class="col-5">
                                <label class="labelName"><b>KYC Doc</b></label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <!-- <p>{{userDetail?.kyc?.document[0]?.docName || 'N/A'}}</p> -->
                                <p>Government ID</p>
                            </div>

                            <!-- <label class="col-md-4">KYC Doc </label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <p class="rap2">{{userKyc?.docName || 'N/A'}}</p>
                            </div> -->
                        </div>
                        <div class="form-group row" *ngIf="userDetail?.kyc?.document[0]?.documentStatus == 'REJECTED' || userDetail?.kyc?.document[0]?.documentStatus == 'PENDING'">
                            <div class="col-5">
                                <label class="labelName"><b>KYC Doc</b></label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <!-- <p>{{userDetail?.kyc?.document[0]?.docName || 'N/A'}}</p> -->
                                <p> Not Accepted</p>
                            </div>

                            <!-- <label class="col-md-4">KYC Doc </label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <p class="rap2">{{userKyc?.docName || 'N/A'}}</p>
                            </div> -->
                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName"><b>KYC Doc ID</b></label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.kyc?.document[0]?.docIdNumber || 'N/A'}}</p>
                            </div>
                            <!-- <label class="col-md-4">KYC Doc ID </label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <p class="rap2">{{userKyc?.docIdNumber || 'N/A'}}</p>
                            </div> -->
                        </div>




                    </div>
                    <div class="text-left mbt10 text-center">
                        <a routerLink="/user-management" class="btn btn-large  max-WT-200 font-100 btn-green">Back</a>
                    </div>
                </div>
            </div>
        </div>

       

        <div class="tab-pane active show mb20" *ngIf="this.currTab == 'WalletTrading'">
            <div class="order-view mt30  mrgn-0-auto">
                <div class="main-block-innner mbt10">
                    <app-wallet-management [userId]="id"></app-wallet-management>
                </div>
            </div>
        </div>


    </div>
</main>
