<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">USER MANAGEMENT ADMIN</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                    


                            <div class="user-main-head sec_head_new sec_head_new_after">
                                <form [formGroup]="adminForm">
                                    <div class="row form-group">
                                        <div class="col-lg-3 col-md-4 col-sm-6 ">
                                            <div class="head_flt_select">
                                                <div class="head_flt_select input-label input-label1">
                                                    <span class="d-flex align-items-center"
                                                        style="margin-left: 5px!important">From Date </span>

                                                    <input type="date" class="form-control form"
                                                        [max]="this.current | date" formControlName="startdate">
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">

                                            <div class="head_flt_select">
                                                <div class="head_flt_select input-label input-label1">
                                                    <span class="d-flex align-items-center">To Date</span>
                                                    <input type="date" class="form-control form"
                                                        [min]="this.adminForm.controls['startdate'].value"
                                                        formControlName="enddate">
                                                </div>
                                            </div>

                                        </div>
                                        <br>

                                        <div class="col-lg-3 col-md-4 " style="margin-top:2%;">
                                        
                                                <div class="input-group filter_search_group w-20">
                                                    <input type="text" class="form-control w-75" style="padding-left: 2%;"
                                                        placeholder="Search by email" formControlName="searchText">
                                                    <div class="input-group-append" style="width: 23%;">
                                                        <button class="btn btn_search_group" type="button"><img
                                                                src="assets/img/icon-search.png" alt="Search" (click)="search()"></button>
                                                    </div>
                                                </div>
                                          
                                        </div>

                                        <div class="col-md-2 user-lst p-0" style="margin-top: 2%; ">
                                            <div class="two-btnline" style="margin-left: 34%;">
                                                <div class="text-left" style="margin-left: 1%">
                                                    <button type="submit" class="btn  btn-theme cus-mr"
                                                        (click)="search()">Search</button>
                                                </div>
                                                <div class="head_flt_select">
                                                    <div class="head_flt_select input-label" style="margin-left: 1%">
                                                        <button type="submit" class="btn  btn-theme"
                                                            (click)="reset()">Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                       
                        <div class="row form-group" style="display: flex; justify-content: flex-end; ">
                            <div class="col-md-2" style="margin-right: 2%">
                                <div class="head_flt_select input-label" style="margin-top: -2%">
                                    <button type="submit" class="btn  btn-theme" style="margin-left: 75%;"
                                        (click)="exportAsXLSX()">EXPORT AS EXCEL </button>

                                </div>
                            </div>
                            <div class="col-md-2">
                                <a routerLink="/add-administator"><i class="fa fa-user-plus" aria-hidden="true"
                                        style="margin-left: 74%;font-size: 25px;"></i></a>
                            </div>

                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th" style="margin:0px;padding:0px; line-height:auto;">
                                        <th scope="col">S.No</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Mobile Number</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Last logged In</th>
                                        <th scope="col" class="action_td_btn3">Action</th>
                                    </tr>
                                </thead>
                                <tbody style="margin:0px;padding:0px; line-height: auto;">
                                    <tr
                                        *ngFor="let data of userAdminData | paginate:{itemsPerPage:20, currentPage:pageNumber, totalItems:userAdminData?.length}; let i=index">
                                        <td>{{20*(this.pageNumber-1)+i+1}}</td>
                                        <td>{{data?.firstName || 'N/A'}}</td>
                                        <td>{{data?.email || 'N/A'}}</td>
                                        <td>{{data?.roleStatus || 'N/A'}}</td>
                                        <td>{{data?.phoneNo || 'N/A'}}</td>
                                        <td>{{data?.userStatus || 'N/A'}}</td>
                                        <td>{{data?.createTime | date}}</td>
                                        <td class="action_td_btn3">
                                            <a routerLink="/admin-detail/{{data?.userId}}"><i class="fa fa-eye"
                                                    aria-hidden="true"></i></a>
                                            <a (click)="delete(data?.userId)"><i class="fa fa-trash"
                                                    aria-hidden="true"></i></a>
                                            <a (click)="block('ACTIVE',data?.userId)" *ngIf="data?.userStatus=='BLOCK'"
                                                data-toggle="modal"><i class="fa fa-ban" style="color: green;"
                                                    aria-hidden="true"></i></a>
                                            <a (click)="block('BLOCK',data?.userId)" *ngIf="data?.userStatus=='ACTIVE'"
                                                data-toggle="modal"><i class="fa fa-ban" aria-hidden="true"></i></a>

                                        </td>

                                    </tr>
                                    <tr *ngIf="userAdminData?.length==0">
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin-left: 10%;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                            *ngIf="userAdminData?.length>0">
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>





<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete Admin </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this Admin?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="deleteUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 *ngIf="userStatus=='ACTIVE'" style="text-align: center; ">Unblock </h3>
                        <h3 *ngIf="userStatus=='BLOCK'" style="text-align: center; ">Block </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p *ngIf="userStatus=='ACTIVE'" style="font-size: 25px;">Are you sure you want to
                                    unblock?</p>
                                <p *ngIf="userStatus=='BLOCK'" style="font-size: 25px;">Are you sure you want to block?
                                </p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>