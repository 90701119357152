<div class="login-wrapper"
    style="background: #b5cff3;background-size: cover;height: 100%;background-repeat: no-repeat;">
    <div class="container-common">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="d-flex align-items-center minheight70vh">
                    <form class="login_box_outer w-100" [formGroup]="loginForm">
                        <div class="login-box max-WT-520" style="opacity: 0.8;
                            background: #244273b3;">
                            <div class="login-right-block">
                                <div class="text-center">
                                    <img src="assets\img\icologo.png" alt="crypto-logo" class="data-img">

                                </div>
                                <div class="login-heading">
                                    <h4>LOGIN</h4>
                                </div>
                                <div class="login-box-body">
                                    <div class="form-group">
                                        <input type="text" formControlName="email"
                                            (keypress)="service.preventSpace($event)" id="loginPhoneNumber"
                                            class="form-control" placeholder="Email"
                                            style="background:transparent ; color: #fff;" />
                                        <div class="text-danger"
                                            *ngIf="loginForm.get('email').hasError('required') && loginForm.get('email').touched">
                                            *Please enter email address.
                                        </div>
                                        <ng-template #check>
                                            <div class="text-danger"
                                                *ngIf="loginForm.get('email').hasError('pattern') && loginForm.get('email').dirty">
                                                *Please enter valid email address.
                                            </div>

                                        </ng-template>

                                    </div>
                                    <div class="form-group eyeicon">
                                        <input [type]="showPassOrText ? 'text': 'password'" maxlength="18"
                                            formControlName="password" (keypress)="service.preventSpace($event)"
                                            class="form-control" placeholder="Password"
                                            style="background:transparent ;color: #fff;" />
                                        <em [class]="showEyeOrCrossEye? 'fa fa-eye':'fa fa-eye-slash'"
                                            aria-hidden="true" (click)="showPassword()" style="color: white;"></em>

                                        <div class="text-danger"
                                            *ngIf="loginForm.controls['password'].hasError('required') && loginForm.controls['password'].touched">
                                            *Please enter password.
                                        </div>
                                     
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-lg-12">
                                            <div class="rememberme-section">
                                                <label class="checkbox-design" style="color:#fff;font-weight: 400;">
                                                    <input type="checkbox" formControlName='rememberMe'
                                                        class="form-control" /><span></span>Remember me
                                                </label>
                                                <label class="checkbox-design" style="color:#fff;font-weight: 400;">
                                                    <span [routerLink]="['/forgot-password']">Forgot Password</span>
                                                </label>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mt40">
                                    <button (click)="onLogin()" [disabled]="loginForm.invalid"
                                        class="btn btn-login btn-large  width100 font-600 resp">LOGIN</button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Google Auth Modal  -->
<div class="modal fade" id="googleAuth" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">Google Authentication Verification</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="removeToken()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="qrCode" class="qr-div mt-4 mb-3">
          <img [src]="qrCode" *ngIf="qrCode" alt="">
        </div>
        <p *ngIf="secretKey" style="padding: 0px;">Secret Key : {{secretKey}}
          <i class="fa fa-clone" aria-hidden="true" style="cursor: pointer;" (click)="copyToClipboard(secretKey)"></i>
        </p>
        <div class="form-group search-div mt-3">
          <input type="address" class="form-control after-input" style="text-align:center;"
            (keypress)="restrictSpace($event);restrictChar($event)" maxlength="6" placeholder="Enter the google code"
            [(ngModel)]="googleAuth">
        </div>
        <div class="btn-modal">
          <button class="btn btn-login btn-large width100 font-600" [disabled]='googleAuth.length != 6'
            (click)="verifyGoogleAuth('Google')">Verify</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Google Auth Modal end  -->

<!-- SMS Auth Modal  -->
<!-- <div class="modal fade" id="smsAuth" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">SMS Authentication Verification</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="removeToken()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group search-div mt-3">
          <input type="address" class="form-control after-input" style="text-align:center;"
            (keypress)="restrictSpace($event);restrictChar($event)" maxlength="6" placeholder="Enter the OTP"
            [(ngModel)]="smsAuth">
        </div>
        <div class="btn-modal">
          <button class="btn theam-btn mr-2" [disabled]='smsAuth.length != 6'
            (click)="verifyGoogleAuth('sms')">Verify</button>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- SMS Auth Modal end  -->