import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-all-vendor-redemption',
  templateUrl: './all-vendor-redemption.component.html',
  styleUrls: ['./all-vendor-redemption.component.css']
})
export class AllVendorRedemptionComponent implements OnInit {
  kycForm: FormGroup;
  pageNumber: number = 1;
  vendorList: any = [];
  userId: any;
  url: string;
  text: any = false;
  itemPerPage : any = 10
  searchText: any
  totalItem : any
  fromDate : any
  toDate : any
  todayDate : any = new Date().toISOString().split('T')[0]
  constructor(
    public route: Router,
    public service: MainService,
    public transform: DatePipe
  ) { }

  ngOnInit(): void {
    this.kyc();
    this.kycForm = new FormGroup({
      status: new FormControl("", Validators.required),
      userName: new FormControl("", Validators.required),
    });
    console.log(this.text);
    // this.kycStatusChecked();
  }

  // api of kyc listing
  kyc() {
    // this.vendorList = [ 
    //   {name : 'Tanveer Haider' , email : 'tanveer@mailinator.com',greatAdd : '4545',status : 'Active' }
    // ]
    let fromDate = Date.parse(this.fromDate)
    let toDate = Date.parse(this.toDate)
    var url = `wallet/get-all-redeem-transaction-history?txnType=REDEEM&page=${this.pageNumber - 1}&pageSize=10${this.searchText ? '&email=' + this.searchText : ''}${fromDate ? '&fromDate='+fromDate :''}${toDate ? '&toDate='+toDate :''}`
  
  this.service.showSpinner()
    this.service.get(url).subscribe(
      (res: any) => {
        console.log("kyc", res);
        if (res.status == 200) {
          this.vendorList = res.data.resultlist;
          this.totalItem = res.data.totalCount
          this.service.hideSpinner()
        }
      },
      (err) => {
        this.totalItem = 0
        this.service.hideSpinner()
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
 
  updateStatus(value,id){

  }
  search() {
    let kycStatus = this.kycForm.value.status;
    let search = this.kycForm.value.userName;
    if (this.kycForm.value.userName && this.kycForm.value.status) {
      this.url =
        "wallet/get-all-transaction-history?txnType=USDTS" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&kycStatus=" +
        kycStatus +
        "&search=" +
        search;
    } else if (this.kycForm.value.userName) {
      this.url =
        "wallet/get-all-transaction-history?txnType=USDTS" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&search=" +
        search;
    } else if (this.kycForm.value.status) {
      this.url =
        "wallet/get-all-transaction-history?txnType=USDTS" +
        (this.pageNumber - 1) +
        "&pageSize=10" +
        "&kycStatus=" +
        kycStatus;
    }
    this.service.get(this.url).subscribe(
      (res: any) => {
        console.log("search", res);

        if (res["status"] == 200) {
          this.vendorList = res.data.list;
          this.totalItem = res.data.totalCount
          this.service.toasterSucc("History found successfully");
        }
      },
      (err) => {
        this.totalItem = 0

        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  changePage(e){
    this.pageNumber =e
    this.kyc()
  }
  // reset
  reset() {
    this.searchText = ''
    this.fromDate = ''
    this.toDate = ''
    this.kyc()
  }
    
/* 
  <td>{{data.vendorName || 'N/A'}}</td>
                                        <td>{{data.vendorEmail || 'N/A'}}</td>
                                        <td>{{data.userName || 'N/A'}}</td>
                                        <td>{{data.userEmail || 'N/A'}}</td>
                                        <td>{{data.amount || 'N/A'}}</td>
                                        <td>{{data.txnId || 'N/A'}}</td>
                                        <td>{{(data.txnTime | date : 'medium' )|| 'N/A'}}</td>
                                        <td>{{data?.status || 'N/A'}}</td>

*/
  exportAsXLSX() {
    let dataArr = [];
    this.vendorList.forEach((element, ind) => {
      dataArr.push({
        "S.No": ind + 1,
        "Vendor Name": element.vendorName ? element.vendorName : "N/A",
        "Vendor Email": element.vendorEmail ? element.vendorEmail : "N/A",
        "User Name": element.userName ? element.userName : "N/A",
        "User Email": element.userEmail ? element.userEmail : "N/A",
        "GRET Amount": element.amount ? element.amount : "N/A",
        "Txn. Id": element.txnId ? element.txnId : "N/A",
        "Date & Time": element.txnTime ? this.transform.transform(element.txnTime,'medium') : "N/A",
        "Status": element.status ? element.status : "N/A",
        
      });
    });
    console.log(dataArr);
    this.service.exportAsExcelFile(dataArr, "REDEMPTION HISTORY");
  }


}
