import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import { element } from "protractor";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-kyc-update",
  templateUrl: "./kyc-update.component.html",
  styleUrls: ["./kyc-update.component.css"],
})
export class KycUpdateComponent implements OnInit {
  kycForm: FormGroup;
  pageNumber: number = 1;
  kycList: any = [];
  userId: any;
  url: string;
  text: any = false;
  itemPerPage : any = 10
  total : any
  today = new Date().toISOString().split('T')[0]
  constructor(
    public route: Router,
    public service: MainService,
    public transform: DatePipe
  ) {}

  ngOnInit(): void {
    
    this.kycForm = new FormGroup({
      fromDate: new FormControl(""),

      toDate: new FormControl(""),

      status: new FormControl(""),
      userName: new FormControl(""),
    });
    this.kyc();
    console.log(this.text);
    this.kycStatusChecked();
  }

  // api of kyc listing
  kyc() {
    let kycStatus = this.kycForm.value.status;
    let search = this.kycForm.value.userName;
    let fromDate = Date.parse(this.kycForm.value.fromDate)
    let toDate = Date.parse(this.kycForm.value.toDate)
    // var url =
    //   "account/admin/kyc-management/filter-kyc-users-list?page=" +
    //   (this.pageNumber - 1) +
    //   "&pageSize=20";
    let url = `account/admin/kyc-management/filter-kyc-users-list?page=${this.pageNumber -1}&pageSize=${this.itemPerPage}${kycStatus ? '&kycStatus=' + kycStatus :''}${search?'&search='+search :''}
    ${fromDate ? '&fromDate='+ fromDate : ''}${toDate ?  '&toDate='+toDate:''}`
    this.service.get(url).subscribe(
      (res: any) => {
        console.log("kyc", res);
        if (res.status == 200) {
          this.kycList = res.data.list;
          this.total = res.data.totalCount
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  paginate(e){
    this.pageNumber = e
    this.kyc()
  }
  kycRequiredCheckData: any;
  kycRequired() {
    console.log(this.text);

    this.service.showSpinner();
    // let kycRequiredCheck = this.coinTokenForm.value.coinNameToken;
    var url =
      "account/check-requried-kyc?checkId=" +
      "1" +
      "&kycIsCheck=" +
      (this.text == true ? "NO" : "YES");

    this.service.post(url, {}).subscribe(
      (res: any) => {
        console.log("j", res);
        this.service.hideSpinner();
        if (res["status"] == 200) {
          // this.kycRequiredCheckData = res["data"]["resultlist"];
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  KycStatusCheck: any;
  // get kyc check status
  kycStatusChecked() {
    var url = "account/get-check-kyc-status";
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.KycStatusCheck = res.data[0];
          this.text = this.KycStatusCheck.kycIsCheck == "YES" ? true : false;
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  // Api of search 
  search() {
    let kycStatus = this.kycForm.value.status;
    let search = this.kycForm.value.userName;
    let fromDate = Date.parse(this.kycForm.value.fromDate)
    let toDate = Date.parse(this.kycForm.value.toDate)
    if(!kycStatus && !search && !fromDate && !toDate) {
      return
      
    }
    let url = `account/admin/kyc-management/filter-kyc-users-list?page=${this.pageNumber -1}&pageSize=${this.itemPerPage}${kycStatus ? '&kycStatus=' + kycStatus :''}${search?'&search='+search :''}
    ${fromDate ? '&fromDate='+ fromDate : ''}${toDate ?  '&toDate='+toDate:''}`
    // if (this.kycForm.value.userName && this.kycForm.value.status) {
    //   this.url =
    //     "account/admin/kyc-management/filter-kyc-users-list?page=" +
    //     (this.pageNumber - 1) +
    //     "&pageSize=10" +
    //     "&kycStatus=" +
    //     kycStatus +
    //     "&search=" +
    //     search;
    // } else if (this.kycForm.value.userName) {
    //   this.url =
    //     "account/admin/kyc-management/filter-kyc-users-list?page=" +
    //     (this.pageNumber - 1) +
    //     "&pageSize=10" +
    //     "&search=" +
    //     search;
    // } else if (this.kycForm.value.status) {
    //   this.url =
    //     "account/admin/kyc-management/filter-kyc-users-list?page=" +
    //     (this.pageNumber - 1) +
    //     "&pageSize=10" +
    //     "&kycStatus=" +
    //     kycStatus;
    // }
    // if(!this.url){
    //   return
    // }
    this.service.get(url).subscribe(
      (res: any) => {
        console.log("search", res);

        if (res["status"] == 200) {
          this.kycList = res.data.list;
          this.service.toasterSucc("User found successfully");
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  // reset
  reset() {
    if (this.kycForm.value.userName || this.kycForm.value.status) {
      this.kycForm.reset();
      this.kyc();
    }
  }
  view(id) {
    console.log("gg", id);
    this.route.navigate(["/kyc-detail"], { queryParams: { id: id } });
  }

  exportAsXLSX() {
    let dataArr = [];
    this.kycList.forEach((element, ind) => {
      dataArr.push({
        "S.No": ind + 1,
        Username: element.firstName ? element.firstName : "N/A",
        Email: element.email ? element.email : "N/A",
        "Mobile Number": element.phoneNO ? element.phoneNO : "N/A",
        "Document ID": element.kycId ? element.kycId : "N/A",
        "Date & Time": element.updateTime ? element.updateTime : "N/A",
        // "Date & Time": element.updateTime ? this.transform.transform(element.updateTime, 'medium') : 'N/A',
        Status: element.kycStatus ? element.kycStatus : "N/A",
      });
    });
    console.log(dataArr);
    this.service.exportAsExcelFile(dataArr, "Kyc List");
  }
}
