import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  loginSub = new BehaviorSubject(``);
  public davidant = 'dividend'
  loginObs = this.loginSub.asObservable();
  code: string;
  httpOptions: { headers: HttpHeaders; };
  
  // websiteURL = 'http://172.16.0.217:5065/'
  
  // public baseUrl = "http://172.16.0.229:3023/" //local ip 

  public baseUrl = "https://java.globalreit.io/" //domain ip 
  // public baseUrl = "https://java.globalreit.io/" //domain ip 
//  public baseUrl ="https://java-blockchainapp.mobiloitte.org/"

  public websiteURL=window.location.protocol + "//" + window.location.host + '/';

  

  constructor(public http: HttpClient, private toastr: ToastrService, private spinner: NgxSpinnerService, public routes: Router) { }

  // Header Managment 
  changeLoginSub(msg) {
    this.loginSub.next(msg);
  }

  // Api Functionlity 
  // Api Structuring Functionality 
  post(url, data) {
    if (localStorage.getItem('Auth')) {
      this.code = localStorage.getItem('Auth')
    }
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'Authorization': `Bearer ${this.code}` })
      };
    }
    return this.http.post(this.baseUrl + url, data, this.httpOptions);
  }

  get(url) {
    if (localStorage.getItem('Auth')) {
      this.code = localStorage.getItem('Auth')
    }
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'Authorization': `Bearer ${this.code}` })
      }
    }
    return this.http.get(this.baseUrl + url, this.httpOptions);
  }

  // Form Data Api Structure
  postApi(endPoint, data): Observable<any> {
    if (localStorage.getItem('Auth')) {
      this.code = localStorage.getItem('Auth')
    }
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {

      this.httpOptions = {
        headers: new HttpHeaders({ 'Authorization': `Bearer ${this.code}` })
      }
    }
    return this.http.post(this.baseUrl + endPoint, data, this.httpOptions);
  }

  public sortAddress(add:string) {
    const sortAdd = `${add.slice(0, 5)}...${add.slice(add.length - 5)}`;
    return sortAdd;
  }

  // Spinner 
  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  // Toaster Functionality
  toasterSucc(msg) {
    this.toastr.success(msg)
  }
  toasterErr(msg) {
    this.toastr.error(msg)
  }
  toasterInfo(msg){
    this.toastr.info(msg)
  }

  //Export
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // Logout
  onLogout() {
    localStorage.clear();
    // window.location.reload();
    this.routes.navigate(['/login']);
    // $('#signout_modal').modal('hide');
  }

  // prevent space
  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    }
  }
   /** Function to restrict character */
   numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  currencyOnly(event,value): boolean {
    console.log(event,value);
    
    const charCode = (event.which) ? event.which : event.keyCode;
    console.log(charCode);
    
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  public getLocationViaIP(ipAddress): Observable<any> {
    return this.http.get("https://api.ipgeolocation.io/ipgeo?apiKey=5cb98274eb3d46eaa0359cf9cbbedf77&ip=" + ipAddress)
  }
    // get IP Address
    public getIPAddress() {
      return this.http.get("https://jsonip.com/?format=json");
    }
    copyToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        this.toasterSucc('Copied to clipboard')
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
    }

}
