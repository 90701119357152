<!-- <main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">MINIMUM WITHDRAWAL AMOUNT</h1>
    </div>
    <div class="content-section"> -->
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col">Withdrawl Fee</th>
                                        <th scope="col">Coin name</th>
                                        <th scope="col">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of coinList  |  paginate:{itemsPerPage:10, currentPage:pageNumber, totalItems:coinList.length?.length}; let i=index">
                                        <td>{{i+1}}</td>
                                        <td *ngIf="!cointAmount[i]">{{item.withdrawalAmount}}</td>
                                        <td *ngIf="cointAmount[i]">
                                            <input 
                                                [(ngModel)]="withdrawAmount[i] = item.withdrawalAmount"
                                                (keypress)="service.preventSpace($event)"
                                                style="text-align: left;" max="6" type="number"
                                                placeholder="Minimum Withdrawl Amount" class="form-control">
                                        </td>

                                        <td>{{item.coinShortName == 'AVT' ? 'GREM' : item.coinShortName}}</td>

                                        <!-- <td><button type="submit" class="btn  btn-theme cus-mr"  style="width: 88%;" routerLink="/update-withdrawl-amount/{{item.coinShortName}}">Edit</button></td> -->
                                        <td>
                                            <button *ngIf="!cointAmount[i]" type="submit" class="btn  btn-theme cus-mr"
                                                (click)="enableInputField(i)" style="width: 88%;">Edit</button>
                                            <button *ngIf="cointAmount[i]" type="submit" class="btn  btn-theme cus-mr"
                                                (click)="updateCoinAmount(item.coinShortName,withdrawAmount[i])"
                                                style="width: 88%;">Update</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end" >
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div>
                        -->

                    </div>
                </div>
            </div>
        </div>
    <!-- </div>
</main> -->

