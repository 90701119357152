<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">View Vendor</h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view max-WT-950 mrgn-0-auto">
                <div class="main-block-innner">
                    <div>
                        <form >
                            <div>
                                <div>
                                    <div>
                                        <div class="row justify-content-around">
                                            <div class="col-md-12 col-sm-12">
                                                <div class="myAlign">
                                                    <h6><strong>
                                                            <h4>Basic Information Detail</h4>
                                                        </strong></h6>
                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">Company Name</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <p>{{vendorDetail?.firstName || 'N/A'}}</p>
                                                           
                                                        </span>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">Company Address</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <p>{{vendorDetail?.address || 'N/A'}}</p>
                                                        </span>
                                                    </div>




                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">Contact Person</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <p>{{vendorDetail?.lastName || 'N/A'}}</p>
                                                        </span>
                                                    </div>

                                              

                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">City</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <p>{{vendorDetail?.city || 'N/A'}}</p>
                                                        </span>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">Country</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                         
                                                        <p>{{vendorDetail?.country || 'N/A'}}</p>
                                                        </span>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">Email</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <p>{{vendorDetail?.email || 'N/A'}}</p>
                                                        </span>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-5 bold">Phone Number</label>
                                                        <span class="col-md-1 sm-d-none">:</span>
                                                        <span class="col-md-6">
                                                            <p>{{vendorDetail?.phoneNo || 'N/A'}}</p>
                                                        </span>
                                                    </div>




                                                </div>

                                            </div>
                                            <div class="col-md-12 col-sm-12">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="myAlign">
                                                            <h6><strong>
                                                                    <h4>Basic Account Detail</h4>
                                                                </strong></h6>
                                                            <div class="form-group row">
                                                                <label class="col-md-5 bold">IBAN</label>
                                                                <span class="col-md-1 sm-d-none">:</span>
                                                                <span class="col-md-6">
                                                                    <p>{{vendorDetail?.iban || 'N/A'}}</p>
                                                                </span>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-md-5 bold">Account Number</label>
                                                                <span class="col-md-1 sm-d-none">:</span>
                                                                <span class="col-md-6">
                                                                    <p>{{vendorDetail?.accountNo || 'N/A'}}</p>
                                                                </span>
                                                            </div>




                                                            <div class="form-group row">
                                                                <label class="col-md-5 bold">Branch</label>
                                                                <span class="col-md-1 sm-d-none">:</span>
                                                                <span class="col-md-6">
                                                                    <p>{{vendorDetail?.branch}}</p>
                                                                </span>
                                                            </div>





                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 mt-2">
                                                        <div class="myAlign">
                                                            <h6><strong>
                                                                    <h4>USDT Address</h4>
                                                                </strong></h6>
                                                            <div class="form-group row">
                                                                <label class="col-md-5 bold">USDT Address</label>
                                                                <span class="col-md-1 sm-d-none">:</span>
                                                                <span class="col-md-6">
                                                                    <p >{{walletAddres || 'N/A'}}&nbsp;<i class="fa fa-clone copyPointer m4p"
                                                                        (click)="copyToClipboard(walletAddres)"
                                                                        aria-hidden="true"></i></p>
                                                                </span>
                                                            </div>





                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 mt-2">
                                                        <div class="myAlign">
                                                            <h6><strong>
                                                                    <h4>Documents:</h4>
                                                                </strong></h6>
                                                            <div class="form-group row">
                                                                <label class="col-md-5 bold">Logo</label>
                                                                <span class="col-md-1 sm-d-none">:</span>
                                                                <span class="col-md-6">
                                                                    <div class="w-100p">
                                                                        <img [src]="vendorDetail?.logo || 'assets/img/no_image.jpeg'" alt="" srcset="">
                                                                    </div>
                                                                    
                                                                    <!-- <p>{{vendorDetail?.logo}}</p> -->
                                                                </span>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-md-5 bold">Trade license</label>
                                                                <span class="col-md-1 sm-d-none">:</span>
                                                                <span class="col-md-6">
                                                                    
                                                                    <div class="w-100p">
                                                                        <img [src]="vendorDetail?.tradeLicenceLogo || 'assets/img/no_image.jpeg'" alt="" srcset="">
                                                                    </div>
                                                                </span>
                                                            </div>





                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 mt-2">
                                                        <div class="myAlign">
                                                            <h6><strong>
                                                                    <h4>Preferred method to receive payment from Global
                                                                        REIT:</h4>
                                                                </strong></h6>
                                                            <div class="form-group row">
                                                                <label class="col-md-5 bold">Preferred Method</label>
                                                                <span class="col-md-1 sm-d-none">:</span>
                                                                <span class="col-md-6">
                                                                    <p>{{vendorDetail?.preferredPaymentMethod || 'N/A'}}</p>
                                                                </span>
                                                            </div>
                                                            





                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 mt-2">
                                                        <div class="myAlign">
                                                            <h6 style="margin-bottom: 25px;"><strong>
                                                                    <h4>Redemption History</h4>
                                                                </strong></h6>
                                                            <div class="form-group">
                                                               <app-vendor-redemption-history></app-vendor-redemption-history>
                                                            </div>
                                                            





                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-around">
                                        
                                            <div class="col-md-12 mt-2 text-center">
                                                <button class="btn  btn-theme cus-mr"
                                                    [routerLink]="['/vendor-list']">Back</button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

